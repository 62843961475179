import { DigitalForm } from "../DigitalForm";
import { findFieldInput } from "../Element/FieldInputContainer";
import { FieldInputElement } from "../Element/FieldInputElement";

export type FormElementPath = [DigitalForm, string];

export const resolvePath = (path: FormElementPath): FieldInputElement => {
  if (!path[0]) return null;
  return findFieldInput(path[0], path[1]);
}


const isNotNull = (data: any): boolean => data !== null;

export const convertPathsToElements = (paths: FormElementPath[]): FieldInputElement[] => {
  return paths.map(resolvePath).filter(isNotNull);
}