import { FieldInputElement } from "./FieldInputElement";

export interface ReadonlyFieldInputContainer {
  readonly id: string;
  numberOfFieldInputs: number;
  fieldInputAtIndex(index: number): FieldInputElement;
}

export interface FieldInputContainer {
  readonly id: string;
  numberOfFieldInputs: number;
  fieldInputAtIndex(index: number): FieldInputElement;
  updateFieldInput(input: FieldInputElement, id: string): void;
  appendFieldInput(input: FieldInputElement): void;
  removeFieldInput(input: FieldInputElement): void;
}

export function isFieldElementContainer(input: any): input is ReadonlyFieldInputContainer {
  return (input as ReadonlyFieldInputContainer).fieldInputAtIndex !== undefined;
}

export const findFieldInput = <T extends FieldInputElement>(container: ReadonlyFieldInputContainer, id: string): T | null => {
  for (let i = 0, l = container.numberOfFieldInputs; i < l; i++) {
    const fieldInput = container.fieldInputAtIndex(i);
    if (fieldInput.id === id) return fieldInput as T;
    if (isFieldElementContainer(fieldInput)) {
      const found = findFieldInput(fieldInput, id);
      if (found !== null) {
        return found as T;
      }
    }
  }
  return null;
};

export const getFieldInputData = (container: ReadonlyFieldInputContainer): any => {
  let value: any = {};
  for (let i = 0, l = container.numberOfFieldInputs; i < l; i++) {
    const fieldInput = container.fieldInputAtIndex(i);
    if (isFieldElementContainer(fieldInput)) {
      value = Object.assign(value, getFieldInputData(fieldInput));
    } else {
      value[fieldInput.id] = fieldInput.value;
    }
  }
  return value;
};

interface FieldCompositeHTMLInput{
  mapKeys: string[];
  subInput(name: string): HTMLElement;
}

const isFieldCompositeHTMLInput = (fieldInput: FieldInputElement): fieldInput is FieldCompositeHTMLInput & FieldInputElement  => {
  // @ts-ignore
  return Array.isArray(fieldInput.mapKeys);
};

export const getFieldInputKeys = (container: ReadonlyFieldInputContainer): string[] => {
  const keys: string[] = [];
  for (let i = 0, l = container.numberOfFieldInputs; i < l; i++) {
    const fieldInput = container.fieldInputAtIndex(i);
    if (isFieldElementContainer(fieldInput)) {
      keys.push(...getFieldInputKeys(fieldInput));
    } else {
      keys.push(fieldInput.id);
      if (isFieldCompositeHTMLInput(fieldInput)) {
        keys.push(...fieldInput.mapKeys.map(key => `${fieldInput.id}-${key}`));
      }
    }
  }
  return keys;
};