import { ConfirmPrintingResultType } from "./ConfirmPrintingResultType";
import { Notifier } from "./Notifier";

export const AfterPrintDialogType = {
  None: "none",
  RetryOrReset: "retryreset",
} as const;

export interface AfterPrintDialogBehaviourCustom {
  message: string;
  buttons: string[];
  onClickButtonAtIndex: (controller: PrintDialogController, index: number) => void;
}

export interface PrintDialogController {
  hide(): void;
  print(): void;
  reset(): void;
}

export type AfterPrintDialogBehaviourTypePreset = typeof AfterPrintDialogType[keyof typeof AfterPrintDialogType];
export type AfterPrintDialogBehaviour = AfterPrintDialogBehaviourTypePreset | AfterPrintDialogBehaviourCustom;

export const PDFPreviewOrientation = {
  Portrait: "portrait",
  Landscape: "landscape"
} as const;
export type PDFPreviewOrientation = typeof PDFPreviewOrientation[keyof typeof PDFPreviewOrientation];

export interface PDFPreviewWindowOptions {
  printConfirmType?: AfterPrintDialogBehaviour;
  resetUrl?: string;
  isiOS?: boolean;
  customPrintFunction?: () => void;
}

export type PageImage = HTMLCanvasElement | HTMLImageElement;

export class PDFPreviewWindow {
  private view: HTMLElement;
  private container: HTMLElement;
  private progressView: HTMLElement;
  private progressBar: HTMLElement;
  private previewView: HTMLElement;
  private previewFrame: HTMLIFrameElement;
  private printConfirmType: AfterPrintDialogBehaviour;
  private printNotifier: Notifier;
  private resetUrl: string;
  private readonly saveButton: HTMLButtonElement;
  public onSave: Function;
  public onCancel: Function;
  public onClose: Function;
  private customPrintFunction?: () => void;

  constructor(view, container, options: PDFPreviewWindowOptions = {}) {
    this.view = view;
    this.container = container;
    this.progressView = this.view.getElementsByClassName("pdf-progress")[0] as HTMLElement;
    this.progressBar = this.progressView.getElementsByClassName("pdf-progress-bar")[0] as HTMLElement;
    this.previewView = this.view.getElementsByClassName("pdf-preview")[0] as HTMLElement;
    this.previewFrame = this.previewView.getElementsByTagName("IFRAME")[0] as HTMLIFrameElement;

    if (options?.isiOS) {
      this.view.addEventListener("touchmove", this.preventScroll, false);
    }

    const cancelButton = this.view.getElementsByClassName("pdf-progress-cancel")[0];
    cancelButton.addEventListener("click", (e) => {
      if (this.onCancel) {
        this.onCancel();
      }
      e.preventDefault();
      return false;
    });
    // hotfix: iOSでまれにクリックが反応しなくなる場合があるため追加
    // 他のスクロール抑制系の処理とバッティングしている可能性がある？
    cancelButton.addEventListener("touchend", (e) => {
      if (this.onCancel) {
        this.onCancel();
      }
      e.preventDefault();
      return false;
    });
    const saveButton = this.view.getElementsByClassName("pdf-preview-save")[0] as HTMLButtonElement;
    saveButton.addEventListener("click", () => {
      if (this.onSave) { this.onSave(); }
    });
    this.saveButton = saveButton;

    const closeButton = this.view.getElementsByClassName("pdf-preview-close")[0];
    closeButton.addEventListener("click", () => {
      this.hide();
      if (this.onClose) { this.onClose(); }
    });

    // 印刷
    this.printConfirmType = options.printConfirmType || AfterPrintDialogType.None;
    this.resetUrl = options.resetUrl || "/";
    this.customPrintFunction = options.customPrintFunction;

    this.saveStyle(this.progressView);
    this.saveStyle(this.previewView);
  }

  getContentHeight(): number {
    const doc = this.previewFrame.contentWindow.document;
    const elm = doc.getElementById("container");
    if (!elm) { return 0; }
    const style = doc.defaultView.getComputedStyle(elm);
    const height = +(style.height.replace("px", ""));
    return isNaN(height) ? 0 : height;
  }

  isHidden(): boolean {
    return this.view.parentNode === null;
  }

  show() {
    if (this.isHidden()) {
      this.container.appendChild(this.view);
    }
  }

  hide() {
    if (this.previewFrame.contentDocument) {
      const images = Array.prototype.slice.call(this.previewFrame.contentDocument.getElementsByClassName("pdf-preview-item"));
      images.forEach((elm) => {
        const canvas = elm.querySelector('canvas');
        //canvasがなくimgが飛んで来ることがあるので
        if (canvas) {
          canvas.width = 0;
          canvas.height = 0;
        }
        elm.parentNode.removeChild(elm);
      });
    }

    if (!this.isHidden()) {
      this.container.removeChild(this.view);
    }

    this.hideProgress();
    this.hidePreview();
    this.isSaveButtonVisible = true;
    if (this.printNotifier) {
      this.printNotifier.close();
      this.printNotifier = null;
    }
    this.printMode = false;
  }

  showProgress() {
    this.show();
    this.restoreStyle(this.progressView);
    this.previewView.style.display = "none";
  }

  hideProgress() {
    this.progressView.style.display = "none";
  }

  get isSaveButtonVisible() {
    return this.saveButton.style.display !== "none";
  }

  set isSaveButtonVisible(visible: boolean) {
    this.saveButton.style.display = visible ? "" : "none";
  }

  showPreview(mode: string) {
    this.show();
    this.saveButton.setAttribute("class", `pdf-preview-save mode-${mode}`);
    this.progressView.style.display = "none";
    this.restoreStyle(this.previewView);
    this.view.querySelector('.pdf-preview-menu').classList.remove('hidden');
  }

  showPreviewWithImage(mode: string, pages: PageImage[]) {
    pages.forEach((page) => {
      const orientation = page.width <= page.height ?
        PDFPreviewOrientation.Portrait : PDFPreviewOrientation.Landscape;
      this.appendPreview(page, orientation);
    });
    this.showPreview(mode);
  }

  hidePreview() {
    this.previewView.style.display = "none";
    this.view.querySelector('.pdf-preview-menu').classList.add('hidden');
  }

  saveStyle(elm) {
    var style = document.defaultView.getComputedStyle(elm);
    elm.setAttribute("data-display", style.display);
  }

  restoreStyle(elm) {
    const display = elm.getAttribute("data-display");
    elm.style.display = display;
  }

  setProgress(currentValue: number, maxValue: number) {
    const progress = Math.max(0, Math.min(currentValue / maxValue * 100, 100));
    this.progressBar.style.width = progress + "%";
  }

  appendPreview(content: HTMLElement, orientation: PDFPreviewOrientation) {
    const template = document.getElementById("template");
    const item = template.getElementsByClassName("pdf-preview-item")[0].cloneNode(true) as HTMLElement;
    item.classList.add(orientation);
    item.appendChild(content);
    const container = this.previewFrame.contentWindow.document.getElementById("container");
    container.appendChild(item);
  }

  print() {
    if (this.customPrintFunction) {
      this.customPrintFunction();
    } else {
      this.previewFrame.focus();
      this.previewFrame.contentWindow.print();
    }
    this.processAfterPrint();
  }

  processAfterPrint() {
    if (this.isHidden()) {
      return;
    }
    if (typeof this.printConfirmType === "string") {
      switch (this.printConfirmType) {
        case AfterPrintDialogType.None:
          this.notifyPrintFinishNone();
          return;

        case AfterPrintDialogType.RetryOrReset:
          this.notifyPrintFinishRetry();
          return;
      }
      const _never: never = this.printConfirmType;
      throw new Error(`this should not happend: ${_never}`);
    } else {
      const behaviour = this.printConfirmType;
      this.printNotifier = Notifier.custom(behaviour.message, behaviour.buttons, (index) => {
        this.printNotifier = null;
        behaviour.onClickButtonAtIndex(({
          hide: () => {
            this.hide();
          },
          print: () => {
            this.print(); // TODO: ここをカスタム印刷ロジックに差し替えられるように
          },
          reset: () => {
            location.href = this.resetUrl;
          }
        }), index);
      });
    }
  }

  private notifyPrintFinishNone() {
    this.printNotifier = Notifier.show("印刷を終了します。", () => {
      this.printNotifier = null;
      this.hide();
    });
  }

  private notifyPrintFinishRetry() {
    this.printNotifier = Notifier.confirmPrinting("入力した内容を印刷して終了します。<br/>印刷が終わっていない場合は必ず印刷してから終了してください。", (result) => {
      this.printNotifier = null;

      switch (result) {
        case ConfirmPrintingResultType.OK:
          location.href = this.resetUrl;
          break;

        case ConfirmPrintingResultType.Cancel:
          this.hide();
          break;

        case ConfirmPrintingResultType.Retry:
          this.print();
          break;
      }
    });
  }

  get printMode() {
    return this.view.classList.contains("print-mode");
  }

  set printMode(enabled: boolean) {
    if (enabled) {
      this.view.classList.add("print-mode");
    } else {
      this.view.classList.remove("print-mode");
    }
  }

  private preventScroll(e) {
    e.preventDefault();
  }
}