import { DigitalForm } from "../DigitalForm";
import { FormElementPath } from "../ruleEngine/FormElementPath";

export type FormElementStringPath = readonly [ string | undefined, string ];
export type NonEmptyFormElementStringPath = readonly [ string, string ];

export const isNonEmptyFormElementPath = (path: FormElementStringPath): path is NonEmptyFormElementStringPath => path[0] !== undefined;

export const toFormElementStringPath = (path: FormElementPath): FormElementStringPath => [path[0] ? path[0].id : undefined, path[1]];
export const toFormElementStringPaths = (paths: FormElementPath[]): FormElementStringPath[] => paths.map(p => toFormElementStringPath(p));

export const toFormElementPath = (formMap: { [keys: string ]: DigitalForm }, path: FormElementStringPath): FormElementPath => [formMap[path[0]], path[1]];
export const toFormElementPaths = (formMap: { [keys: string]: DigitalForm }, paths: readonly FormElementStringPath[]): FormElementPath[] => paths.map(p => toFormElementPath(formMap, p));