//横ボタンONLY
const MenuButtonAppearances = 
[
    "icon",
    "icon-text",
    "text"
] as const; 

type MenuButtonAppearanceType = typeof MenuButtonAppearances[number];

interface MenuButtonAppearanceByDeviceType {
    desktop?:MenuButtonAppearanceType;
    mobile?:MenuButtonAppearanceType;
    tablet_portrait?:MenuButtonAppearanceType;
    tablet_landscape?:MenuButtonAppearanceType;
}
interface MenuButtonCustomDefinition {
    header?: string;
    content?: string;
    device?: MenuButtonAppearanceByDeviceType;
}

type MenuButtonDefinition = MenuButtonCustomDefinition | boolean;

const Buttons = [
    "save-pdf",
    "save-pdf-noqr",
    "save-csvqr",
    "show-qr",
    "print-pdf",
    "print-pdf-noqr",
    "print-qr",
    "print-csvqr",
    "show-qr-single",
    "read-qr-camera",
    "read-qr-image",
    "write-signature",
    "change-page"
] as const;

type ButtonKind = typeof Buttons[number];

export interface MenuButtonOptions {
    isMenu: boolean;
    buttons: {
        [key in ButtonKind]?: MenuButtonDefinition
    }
}

const MenuSections = [
    "save",
    "read",
    "print",
    "signature",
    "debug"
] as const;

type MenuSectionKind = typeof MenuSections[number];
type StyleDisplay = string;
type MenuItem = [HTMLElement, StyleDisplay, MenuSectionKind?];

/**
 * 縦並びメニューを管理する
 */
class MenuControl {
    view: HTMLElement
    menuButton: HTMLElement
    sections: { [key in MenuSectionKind]?: MenuItem }
    lists: { [key in MenuSectionKind]?: MenuItem }
    buttons: { [key in ButtonKind]?: MenuItem }
    visibleButtons: ButtonKind[]
    readonly isEnabled: boolean

    constructor(view: HTMLElement, menuButton: HTMLElement, options?: MenuButtonOptions) {
        this.view = view;
        this.menuButton = menuButton;
        this.sections = {};
        this.visibleButtons = [];

        // セクションとリストを取得
        this.sections = {};
        this.lists = {};
        const elements = this.view.querySelectorAll(".menu-header,.menu-list");
        Array.prototype.slice.call(elements).forEach((elm: HTMLElement) => {
            const key = elm.dataset.menuSection;
            if (!key) {
                return;
            }
            const table = elm.classList.contains("menu-header") ? this.sections : this.lists;
            table[key] = [elm, elm.style.display];
            elm.style.display = "none";
        });

        // ボタンを取得
        this.buttons = {};
        Buttons.forEach((key) => {
            const elm = this.view.getElementsByClassName(key)[0] as HTMLElement
            if (!elm) {
                return;
            }
            const li = elm.parentElement;
            const ul = li.parentElement;
            const section = ul.dataset.menuSection as MenuSectionKind; // TODO: should remove cast
            this.buttons[key] = [li, li.style.display, section];
            li.style.display = "none";
        });

        this.isEnabled = options?.isMenu === true;

        if (!this.isEnabled) {
            this.hide();
            return;
        }

        const definitions = options?.buttons || {};
        Object.entries(definitions).forEach((obj) => {
            const key = obj[0] as ButtonKind;
            const data = obj[1];

            const button = this.buttons[key];
            if (!button) {
                return;
            }

            const elm = button[0];

            if (data === true) {
                // デフォルトのまま
            } else if (data === false) {
                return;
            } else {
                if (data.header) {
                    const header = elm.getElementsByClassName("header")[0] as HTMLElement;
                    header.innerText = data.header;
                }
                if (data.content) {
                    const content = elm.getElementsByClassName("content")[0] as HTMLElement;
                    content.innerText = data.content;
                }
            }

            // 表示用に追加
            this.visibleButtons.push(key);
        });

        this.showDefaultButtons();
    }

    show() {
        this.menuButton.style.display = "block";
    }

    hide() {
        this.menuButton.style.display = "none";
    }

    showAllButtons() {
        const show = (obj: [MenuSectionKind, MenuItem]) => {
            const [elm, display] = obj[1];
            elm.style.display = display;
        };
        Object.entries(this.buttons).forEach(show);
        Object.entries(this.sections).forEach(show);
        Object.entries(this.lists).forEach(show);
        this.setLastItemClassAll();
    }

    showButtons(buttons: ButtonKind[]) {
        const sections = new Set<MenuSectionKind>();
        Object.entries(this.buttons).forEach((obj) => {
            const key = obj[0] as ButtonKind;
            const [elm, display, section] = obj[1];
            if (buttons.includes(key)) {
                elm.style.display = display;
                sections.add(section);
            } else {
                elm.style.display = "none";
            }
        });
        const showHide = (obj: [MenuSectionKind, MenuItem]) => {
            const key = obj[0];
            const [elm, display] = obj[1];
            if (sections.has(key)) {
                elm.style.display = display;
            } else {
                elm.style.display = "none";
            }
        };
        Object.entries(this.sections).forEach(showHide);
        Object.entries(this.lists).forEach(showHide);
        this.setLastItemClassAll();
    }

    showDefaultButtons() {
        this.showButtons(this.visibleButtons);
    }

    setLastItemClass(list: HTMLElement) {
        let lastItem: HTMLElement | null = null;
        Array.prototype.slice.call(list.getElementsByTagName('li')).forEach((elm: HTMLElement) => {
            elm.classList.remove("last-item");
            if (elm.style.display !== "none") {
                lastItem = elm;
            }
        });
        if (lastItem) {
            lastItem.classList.add("last-item");
        }
    }

    setLastItemClassAll() {
        Object.entries(this.lists).forEach((obj) => {
            const [elm] = obj[1];
            if (elm.style.display != "none") {
                this.setLastItemClass(elm);
            }
        });
    }
}

/**
 * 横並びメニューを管理する
 */
class MenuBarControl {
    view: HTMLElement
    buttons: { [key in ButtonKind]?: MenuItem }
    visibleButtons: ButtonKind[]
    readonly isEnabled: boolean

    constructor(view: HTMLElement, options?: MenuButtonOptions) {
        this.view = view;
        this.buttons = {};
        this.visibleButtons = [];

        Buttons.forEach((key) => {
            const elm = this.view.getElementsByClassName(key)[0] as HTMLElement
            if (elm) {
                this.buttons[key] = [elm, elm.style.display];
                elm.style.display = "none";
            }
        });

        this.isEnabled = options?.isMenu === false;

        if (!this.isEnabled) {
            this.hide();
            return;
        }

        this.show();

        const definitions = options?.buttons || {};
        Object.entries(definitions).forEach((obj) => {
            const key = obj[0] as ButtonKind;
            const data = obj[1];

            const button = this.buttons[key];
            if (!button) {
                return;
            }

            const elm = button[0];

            if (data === true) {
                // デフォルトのまま
            } else if (data === false) {
                return;
            } else {
                if (data.header) {
                    elm.innerText = data.header;
                }
                if(data.device){
                    const mobile = "mb-" + ( data.device.mobile || "text" );
                    const desktop = "dk-" + ( data.device.desktop || "text");
                    const tablet_landscape = "tl-" + ( data.device.tablet_landscape || "text" );
                    const tablet_portrait = "tp-" + ( data.device.tablet_portrait || "text" );
                    elm.classList.add("runtime",mobile,desktop,tablet_landscape,tablet_portrait);
                }                
            }

            // 表示用に追加
            this.visibleButtons.push(key);
        });

        this.showDefaultButtons();
    }

    show() {
        this.view.style.display = "flex";
    }

    hide() {
        this.view.style.display = "none";
    }

    showAllButtons() {
        Object.entries(this.buttons).forEach((obj) => {
            const key = obj[0] as ButtonKind;
            const [elm, display] = obj[1];
            elm.style.display = display;
        });
    }

    showButtons(buttons: ButtonKind[]) {
        Object.entries(this.buttons).forEach((obj) => {
            const key = obj[0] as ButtonKind;
            const [elm, display] = obj[1];
            if (buttons.includes(key)) {
                elm.style.display = display;
            } else {
                elm.style.display = "none";
            }
        });
    }

    showDefaultButtons() {
        this.showButtons(this.visibleButtons);
    }
}

/**
 * 複写を見るボタン
 */
class ChangePageControl {
    view: HTMLElement
    display: StyleDisplay
    readonly isEnabled: boolean

    constructor(view: HTMLElement, options?: MenuButtonOptions) {
        const name: ButtonKind = "change-page";

        const elm = view.getElementsByClassName(name)[0] as HTMLElement;
        if (!elm) {
            return;
        }

        this.view = elm;
        this.display = elm.style.display;

        this.isEnabled = options?.buttons[name] != null;

        if (!this.isEnabled) {
            this.hide();
            return;
        }

        this.show();

        const data = options.buttons[name];

        if (data === true) {
            // デフォルトのまま
        } else if (data === false) {
            this.hide();
            return;
        } else {
            if (data.header) {
                elm.innerText = data.header;
            }
        }
    }

    show() {
        if (this.view) {
            this.view.style.display = this.display;
        }
    }

    hide() {
        if (this.view) {
            this.view.style.display = "none";
        }
    }
}

/**
 * 縦並びメニューと横並びメニューをまとめて管理する
 */
export class MenuControls {
    menu: MenuControl
    menuBar: MenuBarControl
    changePage: ChangePageControl

    constructor(view: HTMLElement, menuButton: HTMLElement, options?: MenuButtonOptions) {
        const menuView = view.getElementsByClassName("menu")[0] as HTMLElement;
        this.menu = new MenuControl(menuView, menuButton, options);

        const menuBarView = view.getElementsByClassName("menu-bar")[0] as HTMLElement;
        this.menuBar = new MenuBarControl(menuBarView, options);

        this.changePage = new ChangePageControl(view, options);
    }

    showAllButtons() {
        this.menu.show();
        this.menu.showAllButtons();
        this.menuBar.hide();
    }

    showDefaultButtons() {
        if (this.menu.isEnabled) {
            this.menu.show();
            this.menu.showDefaultButtons();
            this.menuBar.hide();
        } else {
            this.menu.hide();
            this.menuBar.show();
            this.menuBar.showDefaultButtons();
        }
    }
}