import { DigitalApplicationDefinition } from '../../../src/DigitalForm/definition/form/DigitalApplicationDefinition';
import { DigitalFormDefinition } from '../../../src/DigitalForm/definition/form/DigitalFormDefinition';
import { resolveDigitalFormDefinition } from '../../../src/DigitalForm/template/resolver';

// import { } from "../src/templates";

import national_pension from "./national_pension.json";
import { DigitalForm } from '../../../src/DigitalForm/DigitalForm';

export const FormDefinition: DigitalApplicationDefinition = {
    forms: [
        <DigitalFormDefinition>national_pension
    ].map(template => resolveDigitalFormDefinition(template)),
    extraSetup: (formMap: { [keys: string]: DigitalForm }, definitionMap: { [keys: string]: DigitalFormDefinition }) => {
    },
    onFinishPreview: () => {
    }
};
