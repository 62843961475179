// import * as megane from "meganets/dist/megane";
import * as megane from "../../../src/meganets/megane";
import { DigitalFormEditorApplication,FormViewController, FormViewControllerProps } from "../../../src/main";
import { FormDefinition } from "../formData";
import { DigitalApplicationDefinition } from "../../../src/DigitalForm/definition/form/DigitalApplicationDefinition";
import { parse as parseURL } from "url";
import * as querystring from "querystring";
import uiconfig from "./uiconfig.json";
import "./styles/main.scss";

declare var __BUILD_ID__: string;
declare var __IS_DEVSERVER__: boolean;
declare var __GOOGLE_MAP_API_KEY__: string;
declare var __ENV__: string;

const options: FormViewControllerProps["options"] = {
  buildID: __BUILD_ID__,
  menu: uiconfig["menu"],
  debug: __ENV__ === "production"
};

const url = parseURL(location.href);
const query = querystring.parse(url.query);
const definition: DigitalApplicationDefinition = {
  pdfFileName: "申請ナビ書類",
  pdfMetaTitle: "申請ナビ",
  forms: []
};
if (typeof query.forms === "string") {
  const string = decodeURIComponent(query.forms);
  const list = string.split(",");
  for (let i = 0, l = list.length; i < l; i++) {
    const index = parseInt(list[i]) - 1;
    definition.forms.push(FormDefinition.forms[index]);
  }
} else if (typeof query.formIDs === "string") {
  const string = decodeURIComponent(query.formIDs);
  const list = string.split(",");
  for (let i = 0, l = list.length; i < l; i++) {
    const id = list[i];
    const index = FormDefinition.forms.map(form => form.id).indexOf(id);
    if (index >= 0) {
      definition.forms.push(FormDefinition.forms[index]);
    }
  }
} else {
  definition.forms = FormDefinition.forms;
}
definition.extraSetup = FormDefinition.extraSetup;
definition.onFinishPreview = FormDefinition.onFinishPreview;
definition.extraPages = FormDefinition.extraPages;

const frame = new megane.ViewControllerFrame(document.getElementById("container"));
const templates = new megane.TemplateManager(document.getElementById("template"));
const app = new DigitalFormEditorApplication({
  formData: definition,
  frame,
  templates,
  options: {
    formViewControllerOptions: options
  }
});
window.addEventListener("load", () => {
  // @ts-ignore
  app.onAppReady();
});
window.addEventListener('beforeunload', (e) => {
  // @ts-ignore
  if (!__IS_DEVSERVER__) {
    e.returnValue = "編集中のデータが失われますがよろしいですか？";
  }
});

const script = <HTMLScriptElement>document.createElement("script");
// @ts-ignore
script.src = `https://maps.googleapis.com/maps/api/js?key=${__GOOGLE_MAP_API_KEY__}&callback=initMap`;
script.async = true;
script.defer = true;
document.body.appendChild(script);
