import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";

export class FormTextAreaElement extends FormElement implements FieldInputElement {
  public readonly fieldsElement: HTMLElement;
  public readonly textAreaElement: HTMLInputElement;

  constructor(name: string, template: DOMTemplate) {
    super(name, template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");
    const fieldset = node("fieldset");
    let id = name;
    this.textAreaElement = node("textarea", {
      name,
      'data-name': name
    });

    var isComposing = false;

    this.textAreaElement.addEventListener('compositionstart', event => {
      isComposing = true;
      // console.log('composition start');
    });
    this.textAreaElement.addEventListener('compositionend', event => {
      this.updateValue();
      isComposing = false;
      // console.log('composition end');
    });
    this.textAreaElement.addEventListener('input', event => {
      if (!isComposing) {
        this.updateValue();
      }
    });

    //keyupけずったはずなのに復活してたのでけずった
    ["paste", "cut"].forEach(event => {
      this.textAreaElement.addEventListener(event, e => {
        this.updateValue();
      });
    });
    this.virtualInput.setAttribute("data-id", id);
    this.virtualInput.setAttribute("name", id);
    this.virtualInput.addEventListener("change", () => {
      if (this.value !== null){
        this.textAreaElement.value = this.value;
      }
    });
    fieldset.appendChild(this.textAreaElement);
    this.fieldsElement.appendChild(fieldset);
  }

  public focus() {
    this.textAreaElement.focus();
  }

  private updateValue() {
    setTimeout(() => {
      this.value = this.textAreaElement.value;
    }, 0);
  }
}
