Object.setPrototypeOf = Object.setPrototypeOf || function (obj, proto) {
    if (!isIE9() && !isIE10()) {
        obj.__proto__ = proto;
    } else {
        /** IE9,IE10 fix - copy object methods from the protype to the new object **/
        for (var prop in proto) {
            obj[prop] = proto[prop];
        }
    }
    return obj;
};
var isIE9 = function() {
    return navigator.appVersion.indexOf("MSIE 9") > 0;
};
var isIE10 = function() {
    return navigator.appVersion.indexOf("MSIE 10") > 0;
};
/**
 * @file megane.js
 * @version 1.1.0
 */

/**
 * 指定されたクラス名を持つHTML要素を返す
 * @throws {Error} 指定されたクラス名を持つ要素が存在しないか、複数ある場合
 */
function find(element: Element, className: string): Element {
    let elements = element.getElementsByClassName(className);
    if(elements.length !== 1) throw new Error("failed to find unique element whose class name is " + className);
    return elements[0];
}

function removeAllChildren(element: Element){
    while(element.hasChildNodes()){
        element.removeChild(element.childNodes[0]);
    }
}

export function applyObject(element: Element, object){
    for(let key in object){
        let e = element.querySelector("[data-name=\"" + key + "\"]");
        let data = object[key];
        if(e){
            if(e.nodeName === "INPUT"){
                (<HTMLInputElement>e).value = data;
            }else{
                let type = typeof data;
                if(type === "object"){
                    e.innerHTML = "";
                    e.appendChild(data);
                }else{
                    e.textContent = data;
                }
            }
        }
    }
}

export function getObject(element: Element){
    let object = {};
    let elements = element.querySelectorAll("[data-name]");
    for(let i = 0, l = elements.length; i < l; i++){
        let e = elements[i];
        let key = e.getAttribute("data-name");
        if(e.nodeName === "INPUT"){
            const input = <HTMLInputElement>e;
            if(input.type === "checkbox"){
                object[key] = input.checked;
            }else{
                object[key] = input.value;
            }
        }else if(e.nodeName === "SELECT"){
            const select = <HTMLSelectElement>e;
            object[key] = select.value;
        }else{
            object[key] = e.textContent;
        }
    }
    return object;
}

/**
 * @classdesc ViewControllerを格納できるHTMLElementのラッパー
 * @param {HTMLElement} container
 * @constructor
 */
export function ViewControllerFrame(container){
    this._container = container;
    this._viewController = null;
}

Object.defineProperties(ViewControllerFrame.prototype, {
    viewController: {
        get: function(){
            return this._viewController;
        },
        set: function(viewController){
            let container = this._container;
            if(this._viewController !== null){
                this._viewController.willHideView(this);
                container.removeChild(this._viewController._view);
                this._viewController._showing = false;
            }
            this._viewController = viewController;
            if(this._viewController !== null){
                container.appendChild(viewController._view);
                this._viewController._showing = true;
                this._viewController.didShowView(this);
            }
        }
    },
    container: {
        get: function(){
            return this._container;
        }
    },
    title: {
        get: function(){
            return this._title;
        },
        set: function(title){
            this._title = title;
        }
    }
});

ViewControllerFrame.prototype.didResizeFrame = function(app){
    if(this._viewController !== null){
        this._viewController.didResizeView(this);
    }
};

/**
 * @classdesc HTMLElementをコントロールするクラス
 * @param {HTMLElement} view
 * @constructor
 */
export function ViewController(view){
    this._view = view;
    this._showing = false;
    this._listeners = [];
}

/**
 * ViewControllerを継承したサブクラスを生成する
 * @param {Object.<String, String> | Object.<String, Object>} properties
 * @param {Function} constructor
 * @returns {Function}
 */
ViewController.extend = function(properties, constructor){
    function _ViewController(view){
        let ps = _ViewController._properties;
        ViewController.call(this, view);
        for(let i in ps){
            if(typeof ps[i] === "string"){
                this["_" + i] = find(view, ps[i]);
            }else{
                let className = ps[i].className;
                let optional = ps[i].optional;
                try{
                    this["_" + i] = find(view, className);
                }catch(e){
                    if(!optional){
                        throw e;
                    }
                }
            }
        }
        constructor.apply(this, Array.prototype.slice.apply(arguments));
    }

    Object.setPrototypeOf(_ViewController.prototype, ViewController.prototype);

    for(let i in properties){
        Object.defineProperty(_ViewController.prototype, i, {
            get: (function(key) {
                return function(){
                    return this[key];
                }
            })("_" + i)
        });
    }

    _ViewController._properties = properties;

    return _ViewController;
};

Object.defineProperties(ViewController.prototype, {
    view: {
        get: function(){
            return this._view;
        }
    },
    showing: {
        get: function(){
            return this._showing;
        }
    }
});

/**
 * viewに対してイベントリスナーをセットする
 * ここでセットされるイベントリスナーはViewControllerの表示状態に合わせて
 * 自動でadd/removeされる
 * @param {HTMLElement} view
 * @param {Array} args
 */
ViewController.prototype.addEventListenerForView = function(view, args){
    this._listeners.push({
        target: view,
        args: args
    });
    if(this._showing){
        EventTarget.prototype.addEventListener.apply(view, args);
    }
};

/**
 * ViewControllerが表示された時に呼び出される
 * @param navigationController
 */
ViewController.prototype.didShowView = function(navigationController){
    this._showing = true;
    for(let i = 0, l = this._listeners.length; i < l; i++){
        let listener = this._listeners[i];
        EventTarget.prototype.addEventListener.apply(listener.target, listener.args);
    }
    this.didResizeView(navigationController);
};

/**
 * ViewControllerが非表示になる時に呼び出される
 * @param navigationController
 */
ViewController.prototype.willHideView = function(navigationController){
    this._showing = false;
    for(let i = 0, l = this._listeners.length; i < l; i++){
        let listener = this._listeners[l - i - 1];
        EventTarget.prototype.removeEventListener.apply(listener.target, listener.args);
    }
};

ViewController.prototype.didResizeView = function(frame){
};

/**
 * 複数のViewControllerを格納できるViewController
 * @param {HTMLElement} view
 * @constructor
 * @extends ViewController
 */
export function CompositeViewController(view){
    if(!view){
        view = document.createElement('div');
    }
    ViewController.call(this, view);
    this._viewControllers = [];
}

Object.defineProperties(CompositeViewController.prototype, {
    viewControllers:{
        get: function(){
            return this._viewControllers;
        }
    }
});

Object.setPrototypeOf(CompositeViewController.prototype, ViewController.prototype);

CompositeViewController.prototype.didShowView = function(){
    ViewController.prototype.didShowView.call(this);
    for(let i = 0, l = this._viewControllers.length; i < l; i++){
        this._viewControllers[i].didShowView(this)
    }
};

CompositeViewController.prototype.willHideView = function(){
    for(let i = 0, l = this._viewControllers.length; i < l; i++){
        this._viewControllers[i].willHideView(this)
    }
    ViewController.prototype.willHideView.call(this);
};

CompositeViewController.prototype.didResizeView = function(){
    ViewController.prototype.didResizeView.call(this);
    for(let i = 0, l = this._viewControllers.length; i < l; i++){
        this._viewControllers[i].didResizeView(this)
    }
};

/**
 * ViewControllerを追加する
 * @param {ViewController} viewController
 */
CompositeViewController.prototype.addViewController = function(viewController){
    this.attachViewController(viewController, this._view);
};

CompositeViewController.prototype.attachViewController = function(viewController, parentView){
    this._viewControllers.push(viewController);
    if(parentView){
        parentView.appendChild(viewController._view);
    }
    if(this._showing){
        console.log("showing: " + viewController);
        viewController.didShowView(this);
    }
};

/**
 * ViewControllerを削除する
 * @throws {Error} 指定されたViewControllerが追加されていない場合
 * @param {ViewController} viewController
 */
CompositeViewController.prototype.removeViewController = function(viewController){
    let index = this._viewControllers.indexOf(viewController);
    if(index < 0) throw Error("ViewController not found");
    if(this._showing){
        viewController.willHideView(this);
    }
    viewController._view.parentNode.removeChild(viewController._view);
    this._viewControllers.splice(index, 1);
};

export function SingleCompositeViewController(view){
    CompositeViewController.call(this, view);
    this._viewController = null;
}

Object.defineProperties(SingleCompositeViewController.prototype, {
    viewController:{
        get: function(){
            return this._viewController;
        },
        set: function(viewController){
            if(this._viewController){
                this.removeViewController(this._viewController)
            }
            if(viewController){
                this.addViewController(viewController);
            }
        }
    }
});

SingleCompositeViewController.prototype.addViewController = function(viewController){
    if(this._viewControllers.length > 0){
        throw new Error("can not add more than one viewcontrollers");
    }
    this._viewController = viewController;
    CompositeViewController.prototype.addViewController.call(this, viewController);
};

Object.setPrototypeOf(SingleCompositeViewController.prototype, CompositeViewController.prototype);

export function DeferredViewController(view?){
    SingleCompositeViewController.call(this);
    if(!view){
        view = document.createElement('div');
        view.setAttribute("class", "load");
    }
    this.viewController = new ViewController(view);
}

Object.setPrototypeOf(DeferredViewController.prototype, SingleCompositeViewController.prototype);

DeferredViewController.create = function(generator){
    let deferredViewController = new DeferredViewController();
    generator(function(viewController){
        deferredViewController.viewController = viewController;
    });
    return deferredViewController;
};

export function TemplateManager(templates){
    this._templates = templates;
}

TemplateManager.prototype.get = function(name){
    return find(this._templates, name).cloneNode(true);
};

export function SinglePageApplication(frame){
    this._frame = frame;
}

Object.defineProperties(SinglePageApplication.prototype, {
    frame: {
        get: function(){
            return this._frame;
        }
    }
});

SinglePageApplication.prototype.onAppReady = function(){
    let self = this;
    this._resizeAction = function(e){
        self.onWindowResized();
    };
    window.addEventListener("resize", this._resizeAction);

    this._hashchangeAction = function(e){
        self.onHashChanged();
    };
    window.addEventListener("hashchange", this._hashchangeAction);
    this.onHashChanged();
};

SinglePageApplication.prototype.onAppDestroy = function(){
    window.removeEventListener("resize", this._resizeAction);
    this._resizeAction = null;
    window.removeEventListener("hashchange", this._hashchangeAction);
    this._hashchangeAction = null;
};

SinglePageApplication.prototype.rewriteHash = function(hash){
    return hash;
};

SinglePageApplication.prototype.viewControllerForHash = function(hash){
    throw new Error("view controller not found for hash: " + hash);
};

SinglePageApplication.prototype.onWindowResized = function(){
    this._frame.didResizeFrame();
};

SinglePageApplication.prototype.onHashChanged = function(){
    let hash = window.location.hash;
    let argNum = this.rewriteHash.length;
    if(argNum >= 2){
        let self = this;
        this.rewriteHash(hash, function(rewrite){
            if(rewrite !== hash){
                window.location.hash = rewrite;
                return;
            }
            self.showView(hash);
        });
    }else{
        let rewrite = this.rewriteHash(hash);
        if(rewrite !== hash){
            window.location.hash = rewrite;
            return;
        }
        this.showView(hash);
    }
};

SinglePageApplication.prototype.showView = function(hash){
    let argNum = this.viewControllerForHash.length;
    if(argNum === 1){
        let viewController = this.viewControllerForHash(hash);
        if(viewController){
            this._frame.viewController = viewController;
            document.head.title = viewController.title;
        }
    }else if(argNum === 2){
        this.viewControllerForHash(hash, function(viewController){
            if(viewController){
                this._frame.viewController = viewController;
                document.head.title = viewController.title;
            }
        });
    }else{
        throw new Error("invalid viewControllerForHash function");
    }
};

export function downloadData(content, name, mimetype){
    let blob = new Blob([content], {type: "octet/stream"});

    if(window.navigator.msSaveBlob){
        window.navigator.msSaveBlob(blob, name);
    }else{
        let a = document.createElement("a");
        let url = URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }
}

export function DragDropViewController(view){
    ViewController.call(this, view);
    let self = this;
    this._dropArea = find(view, "drop-area");
    this.addEventListenerForView(this._dropArea, ["dragenter", function(e){
        e.stopPropagation();
        e.preventDefault();
    }, false]);
    this.addEventListenerForView(this._dropArea, ["dragover", function(e){
        e.stopPropagation();
        e.preventDefault();
    }, false]);
    this.addEventListenerForView(this._dropArea, ["drop", function(e){
        e.stopPropagation();
        e.preventDefault();
        self.onDragFiles(e.dataTransfer.files);
    }, false]);
}

Object.setPrototypeOf(DragDropViewController.prototype, ViewController.prototype);

DragDropViewController.prototype.onDragFiles = function(files){};