import { FieldInputElement } from "../Element/FieldInputElement";
import { convertPathsToElements, FormElementPath } from "./FormElementPath";

export interface SyncHandler {
  elements: FieldInputElement[];
  exec: (target: FieldInputElement) => void;
}

export const createSyncHandler = (paths: FormElementPath[]): SyncHandler => {
  const elements = convertPathsToElements(paths);
  let syncing = false;
  const context = {
    elements,
    exec: (target: FieldInputElement) => {
      if (syncing || !target || target.disabled) return;
      elements.filter(e => e !== target).forEach(e => {
        syncing = true;
        if (e.value !== target.value) {
          e.value = target.value;
        }
        syncing = false;
      });
    }
  };
  return context;
}