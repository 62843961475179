import { BindDataSource } from "./bind";
import { DigitalForm } from "./DigitalForm";
import { findFieldInput } from "./Element/FieldInputContainer";
import { FieldInputElement } from "./Element/FieldInputElement";
import { FieldBox } from "./FieldBox";
import { FieldDomElement } from "./FieldDomElement";

export class DigitalFormDataSource implements BindDataSource {
  private readonly digitalForm: DigitalForm;
  private readonly box: FieldBox;
  public readonly key: string;
  private readonly id: string;
  private readonly dataName: string;
  private readonly src: FieldInputElement;
  private referenceSource?: DigitalFormDataSource;

  constructor(digitalForm: DigitalForm, path: string) {
    this.digitalForm = digitalForm;
    this.key = path;
    const index = path.indexOf("-");
    if (index >= 0) {
      this.id = path.substring(0, index);
      this.dataName = path.substring(index + 1);
    } else {
      this.id = path;
    }
    [this.box, this.src] = this.findTarget(this.id);
  }

  private findTarget(id: string): [FieldBox, FieldInputElement] {
    const digitalForm = this.digitalForm;
    for (let i = 0, l = digitalForm.numberOfBoxes; i < l; i++) {
      const box = digitalForm.boxAtIndex(i);
      const src = findFieldInput(box, id);
      if (src) {
        return [box, src];
      }
    }
    throw new Error(`data not found: ${this.id}`);
  }

  get value(): string {
    const src = this.src;
    if (src.disabled) return JSON.stringify("");
    if (this.dataName) {
      const subinput = src.subInput(this.dataName);
      if (subinput.disabled) return JSON.stringify("");
      return JSON.stringify(subinput.value);
    }
    return JSON.stringify(src.value);
    }

  focus(dst: HTMLElement): void {
    const box = this.box;
    box.open();
    this.src.focus();
  }

  addChangeListener(callback: () => void): void {
    if (this.dataName) {
      const subinput = this.src.subInput(this.dataName);
      subinput.addEventListener("change", callback);
    }
    this.src.virtualInput.addEventListener('change', callback);
  }

  removeChangeListener(callback: () => void): void {
    if (this.dataName) {
      const subinput = this.src.subInput(this.dataName);
      subinput.removeEventListener("change", callback);
    }
    this.src.virtualInput.removeEventListener("change", callback);
  }
}