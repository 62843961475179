import { parse } from "url";

/**
 * デジタル帳票入力フォームを利用するためのモジュール
 */

/**
 * フォームに入力される人のロールを表す文字列
 */
export type Role = "申請者" | "母" | "配偶者" | "子" | "子-2" | "子-3" | "子-4" | "子-5";

/**
 * 人の生物学的性別を表す文字列
 * 
 * 男性を表すmaleと女性を表すfemaleが利用可能
 */
export type Gender = "male" | "female";

/**
 * デジタル帳票入力フォームで利用可能な元号
 */
export type Gengo = "令和" | "平成" | "昭和" | "大正";

/**
 * 日付を和暦で表すオブジェクト
 * 
 * 例: 令和元年5月1日
 * ```javascript
 * {
 *   gengo: "令和",
 *   year: "元",
 *   month: "5",
 *   date: "1"
 * }
 * ```
 */
export interface JapaneseDate {

  /**
   * 日付の元号
   */
  gengo: Gengo;

  /**
   * 年を文字列で表す
   * 
   * 元年の場合は`"元"`、25年の場合は`"25"`と表記する
   */
  year: string;

  /**
   * 月を文字列で表す
   * 
   * 3月の場合は`"3"`、10月の場合は`"10"`と表記する
   */
  month: string;

  /**
   * 日を文字列で表す
   */
  date: string;
}

/**
 * @ignore
 */
const KAIGEN_DATES = {
  "令和": new Date("2019-05-01"),
  "平成": new Date("1989-01-08"),
  "昭和": new Date("1926-12-25"),
  "大正": new Date("1912-07-30")
}

/**
 * 和暦で表現された日付をDateに変換する
 * 
 * 例:
 * 
 * ```javascript
 * console.log(digitalForm.convertJapaneseDateToDate({
 *   gengo: "令和",
 *   year: "元",
 *   month: "5",
 *   date: "1"
 * }));
 * // Date("2019-05-01")
 * ```
 *
 * @param jDate 和暦で表現された日付
 */
export const convertJapaneseDateToDate = (jDate: JapaneseDate): Date => {
  const gengo = jDate.gengo;
  const month = jDate.month;
  const date = jDate.date;
  const year = (() => {
    const y = KAIGEN_DATES[gengo].getFullYear();
    if (jDate.year === "元") {
      return y;
    }
    return y + parseInt(jDate.year) - 1;
  })();
  return new Date(`${year}-${month.padStart(2, "0")}-${date.padStart(2, "0")}`);
};

/**
 * Dateを和暦に変換する
 * 
 * 例:
 * 
 * ```javascript
 * console.log(digitalForm.convertDateToJapaneseDate(new Date("2019-05-01")));
 * // {
 * //   gengo: "令和",
 * //   year: "元",
 * //   month: "5",
 * //   date: "1"
 * // }
 * ```
 * 
 * @param date 変換するDateオブジェクト
 */
export const convertDateToJapaneseDate = (date: Date): JapaneseDate => {
  const keys = Object.keys(KAIGEN_DATES) as Gengo[];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const kaigenDate = KAIGEN_DATES[key];
    if (kaigenDate <= date) {
      const year = date.getFullYear() - kaigenDate.getFullYear();
      return {
        gengo: key,
        year: year === 0 ? "元" : String(year + 1),
        month: String(date.getMonth() + 1),
        date: String(date.getDate())
      }
    }
  }
  throw new Error("gengo unknown");
};

/**
 * 数字のみで表現された電話番号をハイフンやカッコでフォーマットされた形式に変換する
 * 
 * 現在は携帯電話番号、及び会津若松市を含む一部の固定電話番号のフォーマットにのみ対応している
 * 
 * 対応していない番号の場合は、元の番号を返す
 * 
 * 例:
 * ```javascript
 * console.log(digitalForm.formatPhoneNumber("0242391111"))
 * // "(0242)39-1111"
 * ```
 * @param phonenumber 数字のみで表現された電話番号
 */
export const formatPhoneNumber = (phonenumber: string): string => {
  const match = /^(0[5789]0)(\d\d\d\d)(\d\d\d\d)/.exec(phonenumber);
  if (match) {
    return `(${match[1]})${match[2]}-${match[3]}`;
  }
  const match2 = /^03(\d\d\d\d)(\d\d\d\d)/.exec(phonenumber);
  if (match2) {
    return `(03)${match2[1]}-${match2[2]}`;
  }
  const match3 = /^(024[01234678])(\d\d)(\d\d\d\d)/.exec(phonenumber);
  if (match3) {
    return `(${match3[1]})${match3[2]}-${match3[3]}`;
  }
  const match4 = /^(024)([59]\d\d)(\d\d\d\d)/.exec(phonenumber);
  if (match4) {
    return `(${match4[1]})${match4[2]}-${match4[3]}`;
  }
  return phonenumber;
};

/**
 * フォームに入力される人の情報を表すオブジェクト
 * 
 * 例: 
 * ```javascript
 * {
 *   name: "山田　一郎",
 *   name_kana: "ヤマダ　イチロウ",
 *   birthdate: {
 *     gengo: "昭和",
 *     year: "61",
 *     month: "3",
 *     date: "28"
 *   },
 *   gender: "male",
 *   zipcode: "965-0873",
 *   address: "福島県会津若松市追手町１−１",
 *   phone: "(000)000-0000",
 *   mynumber: "000000000000"
 * }
 * ```
 */
export interface PersonInfo {

  /**
   * 人の名前
   * 
   * 姓名の間は全角スペース「　」で区切る
   * 
   * 例: `山田　太郎`
   */
  name?: string;

  /**
   * 人の名前のフリガナ
   * 
   * 姓名の間は全角スペース「　」で区切る
   * 
   * 例: `ヤマダ　タロウ`
   */
  name_kana?: string;

  /**
   * 人の生年月日
   */
  birthdate?: Date;

  /**
   * 人の生物学的性別
   */
  gender?: Gender;

  /**
   * 人の居住地の郵便番号
   * 
   * ハイフン（-）で区切る
   * 
   * 例: `000-0000`
   */

  zipcode?: string;
  /**
   * 人の居住地
   * 
   * 例: `東京都港区虎ノ門5-12-13`
   */
  address?: string;

  /**
   * 人の連絡先電話番号
   * 
   * 例: `(000)000-0000`
   */
  phone?: string;

  /**
   * 人のマイナンバー
   * 
   * 例: `000000000000`
   */
  mynumber?: string;
}

/**
 * 複数の申請書類をまとめたデジタル帳票にアクセスするためのオブジェクト
 * 
 * 個々の入力項目を直接操作するRaw APIと、
 * 
 * 入力項目のうち、同一の人に関する情報をまとめて操作するPersona APIの2系統のAPIが存在する
 * 
 * ペルソナリンクにより、書類間で共通する同一の人に関する情報が連動して自動的に入力される
 * 
 * 例: 
 * 
 * ```javascript
 * const container = document.querySelector("#container");
 * const form = digitalForm.create(container);
 * form.onLoad = (formInfos) => {
 *   console.log("onload");
 *   // 母のロールに情報をセットする
 *   form.setPersona("母", {
 *     name: "山田　花子",
 *     name_kana: "ヤマダ　ハナコ",
 *     birthdate: {
 *       gengo: "昭和",
 *       year: "63",
 *       month: "7",
 *       date: "24"
 *     },
 *     gender: "female",
 *     zipcode: "965-0873",
 *     address: "福島県会津若松市追手町１−１"
 *   });
 *   // 出産場所をセットする
 *   form.setRawValue("birth_report", "birth_place", "福島病院")
 * };
 * ```
 */
export interface DigitalFormHandler {
  /**
   * 帳票に記入されている、指定されたロールの人の情報を非同期的に取得する
   * 
   * @category Persona API
   * 
   * @param role 取得する対象の人のロール
   * @return 指定されたロールの人の情報を返すPromise
   */
  getPersona(role: Role): Promise<PersonInfo>;

  /**
   * 指定されたロールの人の情報を帳票に記入する
   * 
   * @category Persona API
   * 
   * @param role セットする対象の人のロール
   * @param info セットする人の情報
   */
  setPersona(role: Role, info: PersonInfo): Promise<void>;

  /**
   * 指定した帳票IDをもつ書類の、指定された項目に入力されている情報を非同期的に取得する
   * 
   * @category Raw API
   * 
   * @param formId 取得する入力項目をもつ帳票のID
   * @param inputId 取得する入力項目のID
   * @return 取得した情報を返すPromise
   */
  getRawValue(formId: string, inputId: string): Promise<any>;

  /**
   * 指定した帳票IDをもつ書類の、指定された項目に値をセットする
   * 
   * @category Raw API
   * 
   * @param formId セットする入力項目をもつ帳票のID
   * @param inputId セットする入力項目のID
   * @param value セットする情報
   */
  setRawValue(formId: string, inputId: string, value: any): Promise<void>;

  /**
   * デジタル帳票の読み込み完了時に呼び出されるイベントハンドラ
   * @event
   * 
   * @params formInfos 読み込んだ帳票の情報のリスト
   */
  onLoad?: (formInfos: DigitalFormInfo[]) => void;

  /**
   * @ignore
   */
  onChange?: (key: string, value: any) => void;

  /**
   * デジタル帳票のPDF表示時に呼び出されるイベントハンドラ
   * @event
   */
  onSavePDF?: () => void;

  /**
   * デジタル帳票のQRコード表示時に呼び出されるイベントハンドラ
   * @event
   */
  onSaveQRCode?: () => void;

  /**
   * 読み込まれた帳票のIDと名称のリストを返す
   * 
   * ここで返されるIDはRaw APIにて利用可能
   * 
   * @return 記入する帳票の情報のリスト
   */
  getFormInfos(): DigitalFormInfo[];

  /**
   * 指定された帳票の記入データを返す
   * 
   * @params formId データを取得する情報のID
   * @return 帳票に記入されたデータ
   */
  getFormData(formId: string): { [keys: string]: any };

/**
 * 指定された帳票にデータをセットする
 *
 * @params formId データを取得する情報のID
 * @params data 帳票に記入するデータ
 */
  setFormData(formId: string, data: { [keys: string]: any }): void;
}

/**
 * 帳票の入力項目の情報を保持するオブジェクト
 */
export interface DigitalFormInputInfo {
  /**
   * 帳票の入力項目のID
   * 
   * [[DigitalFormHandler]] のRaw APIにて利用可能
   */
  id: string;

  /**
   * 帳票の入力項目の種別
   */
  kind: "number" | "text" | "radio" | "checkbox" | "select" | "textarea";

  /**
   * 帳票の入力項目名
   */
  title: string;
}

/**
 * 帳票の情報を保持するオブジェクト
 */
export interface DigitalFormInfo {
  /**
   * 帳票のID
   * 
   * [[DigitalFormOption.formIDs]] に指定することで、表示する帳票を制御することができる
   */
  id: string;

  /**
   * 帳票の名称
   */
  title: string;

  /**
   * 帳票の入力項目の配列
   */
  inputs: DigitalFormInputInfo[];
}

/**
 * @ignore
 */
export type RoleMap = { [keys: string]: { [P in keyof PersonInfo]: [string, string][] } };

/**
 * @ignore
 */
const FORM_URL = "https://asukoe-form-aizuwakamatsu.firebaseapp.com/";

/**
 * デジタル帳票を作成する際のオプションパラメータを表すオブジェクト
 * 
 * 例:
 * ```javascript
 * const form = digitalForm.create(
 *   document.querySelector("#container"),
 *   {
 *     // 出生連絡表、子ども医療費助成の受給券の申請の二つだけを表示
 *     formIDs: ["birth_report", "child_medical_expence"]
 *   }
 * );
 * ```
 */
export interface DigitalFormOption {
  /**
   * 表示する帳票のIDの配列
   * 
   * 指定されたIDをもつ帳票を記入することができるデジタル帳票を作成する
   * 
   * 指定がない場合には利用可能な全ての帳票を表示する
   * 
   * 例: `["birth_report", "child_medical_expense"]`
   */
  formIDs?: string[],

  /**
   * 外部デジタル帳票のURLを指定する
   */
  formURL?: string;
}

/**
 * デジタル帳票を作成し、指定されたDiv要素の中に表示する
 * 
 * ```javascript
 * const frame = document.getElementById("#frame");
 * const form = new digitalForm.create(frame);
 * ```
 * 
 * @param frame 帳票を表示するDiv要素
 */
// TODO: Promiseに書き換える
export const create = (frame: HTMLDivElement, option: DigitalFormOption): DigitalFormHandler => {
  const url = option.formURL || FORM_URL;
  const parsedURL = parse(url);
  const iframe = document.createElement("iframe");
  iframe.style.margin = "0";
  iframe.style.padding = "0";
  iframe.style.borderStyle = "none";
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.overflow = "hidden";
  iframe.setAttribute("allow", `camera ${parsedURL.protocol}//${parsedURL.host}`);

  const formIDs = option.formIDs;

  let promiseId = 0;
  const resolveMap = {};
  let forms: DigitalFormInfo[] = [];
  let roleMap: RoleMap;
  const handler: DigitalFormHandler = {
    getPersona: async (role: Role) => {
      if (!roleMap || !roleMap[role]) throw new Error(`role not found: ${role}`);
      const personaDefinition = roleMap[role];
      const persona = {};
      await Promise.all(Object.keys(personaDefinition).map(async propertyName => {
        const properties = personaDefinition[propertyName];
        if (formIDs) {
          const property = properties.filter(property => formIDs.includes(property[0]))[0];
          if (property) {
            persona[propertyName] = await handler.getRawValue(property[0], property[1]);
          }
        } else {
          const property = properties[0];
          persona[propertyName] = await handler.getRawValue(property[0], property[1]);
        }
      }));
      return persona;
    },
    setPersona: async (role: Role, info: PersonInfo) => {
      if (!roleMap || !roleMap[role]) throw new Error(`role not found: ${role}`);
      const persona = roleMap[role]
      await Promise.all(Object.keys(persona).map(propertyName => {
        if (info[propertyName]) {
          if (formIDs) {
            const properties = persona[propertyName];
            const property = properties.filter(property => formIDs.includes(property[0]))[0];
            if (property) {
              return handler.setRawValue(property[0], property[1], info[propertyName]);
            }
          } else {
            const property = persona[propertyName][0];
            return handler.setRawValue(property[0], property[1], info[propertyName]);
          }
        }
      }));
    },
    getRawValue: (formId: string, key: string) => {
      return new Promise(resolve => {
        const id = ++promiseId;
        resolveMap[id] = resolve;
        iframe.contentWindow.postMessage({
          type: "getRawValue",
          id,
          formId,
          key
        }, "*");
      });
    },
    setRawValue: (formId: string, key: string, value: any) => {
      return new Promise(resolve => {
        const id = ++promiseId;
        resolveMap[id] = resolve;
        iframe.contentWindow.postMessage({
          type: "formSetValue",
          id,
          formId,
          key,
          value
        }, "*");
      });
    },
    getFormInfos: () => {
      return forms;
    },
    getFormData: (formId: string) => {
      return new Promise(resolve => {
        const id = ++promiseId;
        resolveMap[id] = resolve;
        iframe.contentWindow.postMessage({
          type: "getFormData",
          id,
          formId
        }, "*");
      });
    },
    setFormData: (formId: string, data: any) => {
      return new Promise(resolve => {
        const id = ++promiseId;
        resolveMap[id] = resolve;
        iframe.contentWindow.postMessage({
          type: "setFormData",
          id,
          formId,
          data
        }, "*");
      });
    }
  };
  iframe.addEventListener("load", () => {
  });
  iframe.addEventListener("error", () => {
    console.log("error");
  });
  if (option.formIDs && option.formIDs.length > 0) {
    iframe.src = `${url}?formIDs=${option.formIDs.join(",")}`;
  } else {
    iframe.src = url;
  }
  frame.appendChild(iframe);
  window.addEventListener("message", (e) => {
    // if (e.data.type === "formValueChange") {
    //   const key = e.data.key;
    //   const value = e.data.value;
    //   console.log("formValueChange", key, value);
    //   data[key] = value;
    //   if (handler.onChange) {
    //     try {
    //       handler.onChange(key, JSON.parse(value));
    //     } catch (e) {
    //       //          console.error("failed to parse", key, value);
    //     }
    //   }
    // } else 
    if (e.data.type === "resolve") {
      const id = e.data.id;
      if (resolveMap[id]) {
        resolveMap[id](e.data.value);
        delete resolveMap[id];
      } else {
        throw new Error(`invalid id: ${id}`);
      }
    } else if (e.data.type === "loadForms") {
      roleMap = e.data.roleMap;
      if (handler.onLoad) handler.onLoad(e.data.forms);
    } else if (e.data.type === "savePDF") {
      if (handler.onSavePDF) handler.onSavePDF();
    } else if (e.data.type === "saveQRCode") {
      if (handler.onSaveQRCode) handler.onSaveQRCode();
    }
  });
  return handler;
};