export class DOMTemplate{
    private templateHolder: HTMLElement;
    constructor(templateHolder: HTMLElement){
        this.templateHolder = templateHolder;
    }

    get(name: string) : HTMLElement {
        const element = this.templateHolder.querySelector(`[data-template=${name}]`);
        if(!element) throw new Error(`Not Found: ${name}`)
        return <HTMLElement>element.cloneNode(true);
    }
}