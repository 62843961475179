import { FieldBox } from "./FieldBox";
import { DOMTemplate } from "../denki";
import { FieldInputContainer, findFieldInput, getFieldInputData, ReadonlyFieldInputContainer } from "./Element/FieldInputContainer";
import { FieldInputElement } from "./Element/FieldInputElement";
import { FieldCompositeElement } from "./FieldCompositeElement";

export class DigitalForm implements FieldInputContainer {
  public readonly element;
  public readonly id;
  public onUpdateFieldInput: (arg0: DigitalForm, newInput: FieldCompositeElement, id: string) => void;
  private _bar: HTMLElement;
  private _boxes: FieldBox[] = [];
  private _fields: HTMLElement;
  public readonly titleElement?: HTMLElement;

  constructor(id: string, template: DOMTemplate) {
    this.id = id;
    let wrapper = template.get("digital-form");
    this.titleElement = wrapper.querySelector("[data-role=title]");
    this._fields = <HTMLElement>wrapper.querySelector("[data-role=fields]");
    this.element = wrapper;
  }

  get title() {
    if (!this.titleElement) return "";
    return this.titleElement.textContent;
  }

  set title(title: string) {
    if (!this.titleElement) return;
    this.titleElement.textContent = title;
  }

  fieldInputAtIndex(index: number): FieldInputElement {
    return this._boxes[index];
  }

  public appendFieldInput(input: FieldInputElement, decorator: HTMLElement = null) {
    throw new Error("not implemented");
  }
  
  public updateFieldInput(newInput: FieldCompositeElement, id: string) {
    const fieldInput = <FieldCompositeElement>findFieldInput(this, id);
    const oldNum = fieldInput.elements.length;
    const newNum = newInput.elements.length;
    if (oldNum > newNum) {
      while (fieldInput.elements.length > newNum) {
        fieldInput.removeFieldInput(fieldInput.elements[fieldInput.elements.length - 1]);
      }
    } else if (oldNum < newNum) {
      for (let i = oldNum; i < newNum; i++) {
        const element = newInput.elements[oldNum];
        newInput.removeFieldInput(element);
        fieldInput.appendFieldInput(element);
      }
    }
    fieldInput.update();
    if (this.onUpdateFieldInput) {
      this.onUpdateFieldInput(this, newInput, id);
    }
  }

  public removeFieldInput(input: FieldInputElement) {
    throw new Error("not implemented");
  }

  get numberOfFieldInputs(): number {
    return this._boxes.length;
  }

  get numberOfBoxes(): number {
    return this._boxes.length;
  }

  indexOfBox(id: string): number {
    for (let i = 0, l = this._boxes.length; i < l; i++) {
      const box = this._boxes[i];
      if (box.id === id) {
        return i;
      }
    }
    throw new Error(`field input was not found: ${id}`);
  }

  box(id: string): FieldBox {
    const index = this.indexOfBox(id);
    return this._boxes[index];
  }

  boxAtIndex(index: number): FieldBox {
    return this._boxes[index];
  }

  appendBox(box: FieldBox, decorator: HTMLElement = null) {
    this._boxes.push(box);
    if (decorator) {
      decorator.appendChild(box.element);
      this._fields.appendChild(decorator);
    } else {
      this._fields.appendChild(box.element);
    }
    box.element.querySelector(".num").textContent = String(this._boxes.length);
  }

  get data() {
    return Object.assign({}, ...this._boxes.map(box => getFieldInputData(box)));
  }

  set data(data) {
    if (!data) return;
    Object.keys(data).forEach(id => {
      const value = data[id];
      this._boxes.forEach(box => {
        const found = findFieldInput(box, id);
        if (found === null) {
          return;
        }
        found.value = value;
      });
    });
  }
}
