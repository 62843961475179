
export function node(name, attributes = {}, textContent = null) {
  const node = document.createElement(name);
  for (let key in attributes) {
    if (!attributes.hasOwnProperty(key)) continue;
    node.setAttribute(key, attributes[key]);
  }
  if (textContent) {
    node.textContent = textContent;
  }
  return node;
}