import { FormElement } from "./FormElement";
import { DOMTemplate } from "../../denki";
import { node } from "../Util";
import { FieldInputElement } from "./FieldInputElement";
import { OptionType } from "../definition/OptionType";

export class FormCheckBoxElement extends FormElement<string[]> implements FieldInputElement {
  public readonly fieldsElement: HTMLElement;
  public readonly selectAllButton: HTMLButtonElement;
  public readonly deselectAllButton: HTMLButtonElement;
  private readonly map: { [key: string]: HTMLInputElement } = {};

  constructor(name: string, options: OptionType[], template: DOMTemplate) {
    super(name, template.get("field-element"));
    this.fieldsElement = this.element.querySelector("[data-role=elements]");

    const operationButtonWrapper = node('div', { "class": "operation_button_wrapper" });
    const selectAllButton = node("button", { "class": "select_all_button" }, "すべて選択");
    selectAllButton.addEventListener("click", () => {
      this.value = Object.keys(this.map);
    });
    this.selectAllButton = selectAllButton;
    const deselectAllButton = node("button", { "class": "deselect_all_button" }, "選択解除");
    deselectAllButton.addEventListener("click", () => {
      this.value = [];
    });
    this.deselectAllButton = deselectAllButton;
    operationButtonWrapper.appendChild(selectAllButton);
    operationButtonWrapper.appendChild(deselectAllButton);
    this.fieldsElement.appendChild(operationButtonWrapper);

    const fieldset = node('div', { "class": "checkbox_wrapper" });
    const id = name;
    this.virtualInput.setAttribute("data-id", id);
    this.virtualInput.setAttribute("name", id);
    for (let i = 0, l = options.length; i < l; i++) {
      const option = options[i];
      const [key, label] = (() => {
        if (typeof option === "string") {
          return [option, option];
        } else {
          return [option.value, option.label];
        }
      })();
      const optionName = `${name}-${key}`;
      const dataId = optionName;
      const elementId = `form_element-${dataId}`;
      const input = node('input', {
        type: 'checkbox',
        name: id,
        'data-name': optionName,
        'data-id': dataId,
        'data-label': label,
        id: elementId,
        value: key
      });
      input.addEventListener("change", () => {
        this.value = Object.keys(this.map).filter(key => this.map[key].checked).map(key => this.map[key].value);
      });
      this.map[key] = input;
      // if (data.default && data.default === key) {
      //   input.checked = true;
      // }
      const wrap = node('div', { "class": "field-checkbox" });
      wrap.appendChild(input);
      wrap.appendChild(node('label', { for: elementId }, label));
      fieldset.appendChild(wrap);
    }
    this.fieldsElement.appendChild(fieldset);
    this.virtualInput.addEventListener("change", () => {
      const inputs = Array.from(this.fieldsElement.querySelectorAll<HTMLInputElement>(`[name="${id}"]`));
      inputs.forEach(input => input.checked = this.value && this.value.includes(input.value));
    });
    this.value = undefined;
  }

  public get mapKeys(): string[] {
    return Object.keys(this.map);
  }

  get validationErrors(): string[] {
    if (this.required && (!this.value || this.value.length === 0)) {
      return ["入力してください"];
    }
    return [];
  }

  public subInput(key: string) {
    return this.map[key];
  }

  public focus() {
  }

  setValue(value: string[]) {
    const v = (() => {
      if (!value) return undefined;
      if (!Array.isArray(value)) {
        return [value];
      }
      return value;
    })();
    super.setValue(v);
  }
}