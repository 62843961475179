export interface BindDataSource {
    key: string;
    value: string
    focus: (HTMLElement) => void,
    addChangeListener: (func: () => void) => void
    removeChangeListener: (func: () => void) => void
}

export interface BindDataStorage {
    update: (src: BindDataSource, manager: DataBindManager) => void;
    onRegister?: (manager: DataBindManager, key: string) => void;
    onUnregister?: (manager: DataBindManager, key: string) => void;
}

const canvas = document.createElement("canvas");
const context = canvas.getContext("2d");

export class HTMLElementBindDataStorage implements BindDataStorage {
    private element: HTMLElement;
    private callback: () => void;

    constructor(element: HTMLElement) {
        this.element = element;
    }

    onRegister(manager: DataBindManager, key: string) {
        this.callback = () => {
            manager.onDestinationClick({
                key,
                storage: this
            });
        }
        this.element.addEventListener("click", this.callback);
    }

    onUnregister(manager: DataBindManager, key: string) {
        this.element.removeEventListener("click", this.callback);
        this.callback = undefined;
    }

    update(src: BindDataSource, manager: DataBindManager) {
        const dst = this.element;
        const value = src.value;
        const dataType = dst.getAttribute('data-type');
        if (dataType === 'radio') {
            const targetClass = dst.getAttribute('data-target-class');
            if (value && dst.getAttribute('data-target-value') === JSON.parse(value)) {
                dst.classList.add(targetClass);
            } else {
                dst.classList.remove(targetClass);
            }
        } else {
            let parsed = manager.resolveReference(src, this);
            const modifier = dst.getAttribute("data-modifier");
            if (modifier === "gengo") {
                parsed = parsed === "1" ? "元" : parsed;
            }
            if (modifier === "no-space") {
                parsed = parsed.replace(/[ 　\t]/, "");
            }
            if (dataType === "image") {
                dst.innerHTML = `<img src="${parsed}" style="height: 100%">`
            } else if (dataType === 'map') {
                const locationInfo = parsed;
                dst.innerHTML = "";
                if (parsed) {
                    dst.classList.remove("handwriting");
                    const image = <HTMLImageElement>document.createElement("img");
                    const query = `center=${locationInfo.center.lat},${locationInfo.center.lng}`
                        + `&zoom=${locationInfo.zoom}&size=640x400&scale=2&key=${manager.mapApiKey}`
                        + `&style=saturation:-100&style=feature:poi|visibility:on`
                        + `&style=feature:transit|visibility:off`
                        + `&style=feature:administrative|visibility:on`
                        + `&style=feature:road|visibility:simplified`
                        + `&markers=size:mid|color:black||${locationInfo.marker.lat},${locationInfo.marker.lng}`;
                    const url = `https://maps.googleapis.com/maps/api/staticmap?${query}`;
                    image.width = 640;
                    image.height = 400;
                    image.setAttribute('src', url);
                    dst.appendChild(image);
                } else {
                    dst.classList.add("handwriting");
                }
            } else if (dataType === 'checkbox') {
                const targetClass = dst.getAttribute('data-target-class');
                const target = dst.getAttribute('data-target-value');
                const operator = dst.getAttribute('data-target-operator');
                if (typeof parsed === "string") {
                    if (parsed === target) {
                        dst.classList.add(targetClass);
                    } else {
                        dst.classList.remove(targetClass);
                    }
                } else if (parsed !== null && parsed.includes(target) === (operator !== "not")) {
                    dst.classList.add(targetClass);
                } else {
                    dst.classList.remove(targetClass);
                }
            } else if (dataType === "char") {
                let str = String(parsed || "");
                if (dst.getAttribute("data-modifier") === "gengo") {
                    str = str === "1" ? "元" : str;
                }
                const offset = parseInt(dst.getAttribute("data-offset"));
                const align = dst.getAttribute("data-digit-align");
                if (align === "right") {
                    const length = str.length;
                    if (length <= offset) {
                        dst.textContent = "";
                    } else {
                        dst.textContent = str.substr(length - offset - 1, 1);
                    }
                } else if (!align || align === "left") {
                    const length = str.length;
                    if (length <= offset) {
                        dst.textContent = "";
                    } else {
                        dst.textContent = str.substr(offset, 1);
                    }
                }
            } else {
                if (parsed !== undefined) {
                    dst.textContent = parsed;
                } else {
                    dst.textContent = "";
                }
            }
            const textContent = dst.textContent;
            const initialFontSize = parseInt(dst.getAttribute("data-font-initial-size"));
            dst.style.fontSize = initialFontSize ? `${initialFontSize}px` : "";
            if (dst.textContent && dst.offsetWidth > 0 && dst.getAttribute("data-auto-font-scale") !== "false") {
                const computedStyle = getComputedStyle(dst);
                let fontSize = parseInt(computedStyle.getPropertyValue("font-size").replace("px", ""));
                const fontFamily = computedStyle.getPropertyValue("font-family");
                try {
                    do {
                        dst.style.fontSize = `${fontSize}px`;
                        context.font = `${fontSize}px ${fontFamily}`;
                        fontSize--;
                    } while (fontSize > 1 && context.measureText(textContent).width > dst.offsetWidth);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    }
}

export interface BindDataDestination {
    key: string;
    storage: BindDataStorage;
}

export class DataBindManager {
    public readonly mapApiKey: string;
    private sourceMapper: { [key: string]: { src: BindDataSource, callback: () => void } } = {};
    private destinations: BindDataDestination[] = [];

    constructor(mapApiKey: string = null) {
        this.mapApiKey = mapApiKey;
    }

    onSourceUpdate(src: BindDataSource) {
       this.destinations.filter(dst => dst.key === src.key).forEach(dst => {
            this.updateDestination(src, dst);
       });
    }

    onDestinationClick(dst: BindDataDestination) {
        const source = this.sourceMapper[dst.key];
        if (source) {
            source.src.focus(dst.storage);
        }
    }

    clearAllSources() {
        Object.keys(this.sourceMapper).forEach(key => {
            const source = this.sourceMapper[key];
            source.src.removeChangeListener(source.callback);
        });
        this.sourceMapper = {};
    }

    registerSource(src: BindDataSource) {
        if (!this.sourceMapper[src.key]) {
            const callback = () => {
                this.onSourceUpdate(src);
            };
            this.sourceMapper[src.key] = {
                src,
                callback
            };
            src.addChangeListener(callback);
        }
    }

    clearAllDestinations() {
        this.destinations.forEach(dst => {
            if (dst.storage.onUnregister) {
                dst.storage.onUnregister(this, dst.key);
            }
        });
        this.destinations = [];
    }

    _resolveReference(key: string): BindDataSource {
        const source = this.sourceMapper[key];
        if (!source) return null;
        return source.src;
    }

    resolveDataAndWatch(value: string, src: BindDataSource, dst: BindDataStorage): any {
        if (value.indexOf("refer:") === 0) {
            const key = value.substring(6);
            const ref = this._resolveReference(key);
            if (!ref) return null;
            const callback = () => {
                const v = JSON.parse(src.value);
                if (v === value || (Array.isArray(v) && v.includes(value))) {
                    dst.update(src, this);
                }
                ref.removeChangeListener(callback)
            }
            ref.addChangeListener(callback);
            return this.resolveReference(ref, dst);
        }
        return value;
    }

    public resolveReference(src: BindDataSource, dst: BindDataStorage): any {
        const valueJSON = src.value;
        const value = JSON.parse(valueJSON);
        if (typeof value === "string") {
            return this.resolveDataAndWatch(value, src, dst);
        } else if (Array.isArray(value)) {
            return value.map(v => this.resolveDataAndWatch(v, src, dst));
        }
        return value;
    }

    updateDestination(src: BindDataSource, dst: BindDataDestination) {
        dst.storage.update(src, this);
    }

    registerDestination(dst: BindDataDestination) {
        if (dst.storage.onRegister) {
            dst.storage.onRegister(this, dst.key);
        }
        this.destinations.push(dst);
        const source = this.sourceMapper[dst.key];
        if (source) {
            this.updateDestination(source.src, dst);
        }
    }
}
