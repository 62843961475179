import { DOMTemplate } from "../denki";
import { FieldInputElementWrapper } from "./FieldInputElementWrapper";
import { FieldBox } from "./FieldBox";

export type FieldDomInputElement = HTMLInputElement | HTMLSelectElement;

export class FieldDomElement extends FieldInputElementWrapper {
  private map: { [key: string]: FieldDomInputElement } = {};
  public calculateValue?: (element: FieldDomElement) => any;
  public deserializeValue?: (element: FieldDomElement, value: any) => void;
  public validator?: any;

  constructor(name: string | null, templateName: string, template: DOMTemplate, manualUpdate: boolean = false) {
    super(name, template);
    const element = template.get(templateName);
    const id = this.id;
    const dataFields = element.querySelectorAll<FieldDomInputElement>("[data-name]");
    for (let i = 0, l = dataFields.length; i < l; i++) {
      const dataField = dataFields[i];
      const elementName = dataField.getAttribute('data-name');
      const elementId = `${id}-${elementName}`;
      dataField.setAttribute('data-id', elementId);
      dataField.setAttribute("id", `form_element-${elementId}`);
      this.map[elementName] = dataField;
      if (!manualUpdate && dataField.getAttribute("data-kind") !== "readonly") {
        this.registerInput(dataField);
      }
    }
    const dataLabels = element.querySelectorAll<HTMLLabelElement>("label[data-for]");
    for (let i = 0, l = dataLabels.length; i < l; i++) {
      const dataLabel = dataLabels[i];
      const elementName = dataLabel.getAttribute("data-for");
      const dataTarget = this.map[elementName];
      if (!dataTarget) {
        throw new Error(`invalid target: data called ${elementName} was not found`);
      }
      dataLabel.setAttribute("for", dataTarget.getAttribute("id"));
    }
    this.elementsWrapper.appendChild(element);
  }

  public get mapKeys(): string[] {
    return Object.keys(this.map);
  }

  public subInput(key: string) {
    return this.map[key];
  }

  calcCompositeValue() {
    const compositeValue = {};
    for (let i in this.map) {
      compositeValue[i] = this.map[i].value;
    }
    return {
      value: this.value,
      compositeValue
    };
  }

  calcValue() {
    const calculateValue = this.calculateValue;
    const elementsWrapper = this.elementsWrapper;
    if (calculateValue) {
      return calculateValue(this);
    } else if (elementsWrapper.querySelectorAll("input").length === 1) {
      return elementsWrapper.querySelector("input").value;
    }
    return "undefined";
  }

  focus() {
    const inputs = this.element.querySelectorAll<HTMLElement>("input, select, textarea");
    if (inputs.length > 0) {
      this.box.open();
      inputs[0].focus();
    }
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(disabled) {
    if (disabled === this._disabled) return;
    this._disabled = disabled;
    for (let i in this.map) {
      if (!this.map.hasOwnProperty(i)) continue;
      const element = this.map[i];
      if (disabled) {
        element.setAttribute("disabled", "true");
      } else {
        element.removeAttribute("disabled");
      }
    }
    this.update();
  }

  get validationErrors(): string[] {
    for (let name in this.map) {
      if (!this.map.hasOwnProperty(name)) continue;
      this.map[name].classList.remove("error");
    }

    const value = this.value;
    const parsed = value;
    let errors = [];
    if (this.required && typeof parsed === "string" && parsed.replace(/\s/g, "").length === 0) {
      errors.push("入力してください");
    }
    const validator = this.validator;
    if (typeof validator === "object") {
      for (let name in this.map) {
        if (!this.map.hasOwnProperty(name)) continue;
        if (validator[name]) {
          const input = this.map[name];
          input.classList.remove("error");
          const inputErrors = validator[name].call(this, input.value, this);
          if (inputErrors.length > 0) {
            errors = errors.concat(inputErrors);
            input.classList.add("error");
          }
        }
      }
    } else if (typeof validator === "function") {
      errors = errors.concat(validator(this));
      if (errors.length > 0) {
        for (let name in this.map) {
          if (!this.map.hasOwnProperty(name)) continue;
          this.map[name].classList.add("error");
        }
      }
    }
    return errors;
  }

  get value() {
    return this.disabled ? null : this.calcValue();
  }

  set value(value: string) {
    const deserializeValue = this.deserializeValue;
    if (deserializeValue) {
      this._changeEventDisabled = true;
      deserializeValue(this, value);
      this._changeEventDisabled = false;
    } else {
      const inputs = this.elementsWrapper.querySelectorAll("input");
      if (inputs.length === 1) {
        inputs[0].value = value;
        const e = document.createEvent("HTMLEvents");
        e.initEvent("change", false, true);
        inputs[0].dispatchEvent(e);
        this.virtualInput.value = JSON.stringify(value);
      }
    }
    this.updateValidation();
    this.dispatchChangeEvent();
  }

  subValue(key: string) {
    return this.disabled ? null : this.subInput(key).value;
  }
}