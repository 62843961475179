import { DOMTemplate } from "./denki";
import { FieldBox } from "./DigitalForm/FieldBox";
import { DigitalForm } from "./DigitalForm/DigitalForm";
import { FieldInputElement } from "./DigitalForm/Element/FieldInputElement";
import { FormTextElement } from "./DigitalForm/Element/FormTextElement";
import { FormRadioElement } from "./DigitalForm/Element/FormRadioElement";
import { FormSelectElement } from "./DigitalForm/Element/FormSelectElement";
import { FormNumberElement } from "./DigitalForm/Element/FormNumberElement";
import { FieldDomElement } from "./DigitalForm/FieldDomElement";
import { FieldCompositeElement } from "./DigitalForm/FieldCompositeElement"
import { FormElement } from "./DigitalForm/Element/FormElement";
import { FormTextAreaElement } from "./DigitalForm/Element/FormTextAreaElement";
import { FormCheckBoxElement } from "./DigitalForm/Element/FormCheckBoxElement";
import { FieldInputContainer, findFieldInput, ReadonlyFieldInputContainer } from "./DigitalForm/Element/FieldInputContainer";
import { FormZipCodeElement, ZipcodeReferenceType } from "./DigitalForm/Element/FormZipCodeElement";
import { FormPhoneNumberElement } from "./DigitalForm/Element/FormPhoneNumberElement";
import { ElementCondition, FieldConditionLocalCriteria, FieldSelector, isFieldConditionAndCriteria } from "./DigitalForm/condition";
import { ElementInterface } from "./DigitalForm/definition/element/ElementInterface";
import { ElementDefinition } from "./DigitalForm/definition/element/ElementDefinition";
import { TextElementDefinition } from "./DigitalForm/definition/element/TextElementDefinition";
import { NumberElementDefinition } from "./DigitalForm/definition/element/NumberElementDefinition";
import { Validator } from "./DigitalForm/definition/Validator";
import { RadioElementDefinition } from "./DigitalForm/definition/element/RadioElementDefinition";
import { CheckBoxElementDefinition } from "./DigitalForm/definition/element/CheckBoxElementDefinition";
import { SelectElementDefinition } from "./DigitalForm/definition/element/SelectElementDefinition";
import { TextAreaElementDefinition } from "./DigitalForm/definition/element/TextAreaElementDefinition";
import { ZipCodeElementDefinition } from "./DigitalForm/definition/element/ZipCodeElementDefinition";
import { PhoneNumberElementDefinition } from "./DigitalForm/definition/element/PhoneNumberElementDefinition";
import { FieldDomTemplate } from "./DigitalForm/definition/element/FieldDomTemplate";
import { Persona } from "./DigitalForm/persona/Persona";
import { DigitalFormDefinition } from "./DigitalForm/definition/form/DigitalFormDefinition";
import { DigitalFormBoxDefinition } from "./DigitalForm/definition/form/DigitalFormBoxDefinition";
import { FieldObjectConcreteDefinition } from "./DigitalForm/definition/element/FieldObjectConcreteDefinition";
import { TemplateManager } from "./DigitalForm/template/TemplateManager";
import { convertLocalRuleToGlobalRule, FormGlobalRule, FormLocalRule } from "./DigitalForm/rule";
import { FormDefinitionMap, FormMap } from "./DigitalForm/definition/form/DigitalApplicationDefinition";
import { FormElementStringPath, toFormElementStringPath, toFormElementStringPaths } from "./DigitalForm/ruleCommand/FormElementStringPath";
import { SyncRuleCommand } from "./DigitalForm/ruleCommand/command";
import { FormElementPath } from "./DigitalForm/ruleEngine/FormElementPath";
import { DigitalFormRuleEngine } from "./DigitalForm/ruleEngine/DigitalFormRuleEngine";
import { FormCalculationRule } from "./DigitalForm/rule/calculation";
import { FormSyncRule } from "./DigitalForm/rule/sync";

function setupFormElement<T>(definition: ElementInterface, element: FormElement<T>) {
    if (definition.title) {
        element.title = definition.title;
    }
    if (definition.description) {
        element.description = definition.description;
    }
    if (definition.required) {
        element.required = definition.required;
    }
    if (definition.foldable) {
        element.foldable = definition.foldable;
    }
}

function setupPreSuffix(definition: TextElementDefinition | NumberElementDefinition | SelectElementDefinition, element: FormTextElement | FormNumberElement | FormSelectElement) {
    if (definition.prefix) {
        element.prefixElement.textContent = definition.prefix;
    }
    if (definition.suffix) {
        element.suffixElement.textContent = definition.suffix;
    }
}

const buildValidator = (validator: Validator): (valiue: any) => any[] => {
    if (validator === "StartWithJapanese") {
        return TemplateManager.Util.createValidatorStartWithJapaneseCharacter();
    }
    if (validator === "NumberOnly") {
        return TemplateManager.Util.createValidatorDigit();
    }
    if (validator === "KatakanaOnly") {
        return TemplateManager.Util.createValidatorKatakana();
    }
    if (validator === "ZenkakuOnly") {
        return TemplateManager.Util.createValidatorZenkaku();
    }
    if (validator === "HankakuOnly") {
        return TemplateManager.Util.createValidatorHankaku();
    }
    if (validator === "HiraganaOnly") {
        return TemplateManager.Util.createValidatorHiragana();
    }
    if (validator.kind === "SpecifiedLengthDigitsOnly") {
        return TemplateManager.Util.createValidatorDigitPattern("", validator.pattern);
    }
    if (validator.kind === "SpecifiedLengthDigitsOnlyHankaku") {
        return TemplateManager.Util.createValidatorDigitPatternHankaku("", validator.pattern);
    }
    if(validator.kind === "SpecifiedLengthDigitsOnlyHankakuEisuji"){
        return TemplateManager.Util.createValidatorDigitPatternHankakuEisuji("",validator.pattern);
    }
    if(validator.kind === "SpecifiedLengthDigitsOnlyHankakuSuji"){
        return TemplateManager.Util.createValidatorDigitPatternHankakuSuji("",validator.pattern);
    }
    if(validator.kind === "LimitLengthOnlyZenkaku"){
        return TemplateManager.Util.createValidatorDigitLimitZenkaku("",validator.pattern);   
    }
    if(validator.kind === "LimitLengthOnlyZenkakuKatakana"){
        return TemplateManager.Util.createValidatorDigitLimitZenkakuKatakana("",validator.pattern);   
    }
    if(validator.kind === "LimitLengthOnlyHankakuEisuji"){
        return TemplateManager.Util.createValidatorDigitLimitHankakuEisuji("",validator.pattern);   
    }
    if(validator.kind === "LimitLengthOnlyZenkakuEiji"){
        return TemplateManager.Util.createValidatorDigitLimitZenkakuEiji("",validator.pattern);   
    }
    //New Validator
    if (validator === "Katakana") {
        return TemplateManager.Util.createValidatorKatakanaWithSpace();
    }
    if (validator === "KatakanaNoSpace") {
        return TemplateManager.Util.createValidatorKatakanaNoSpace();
    }
    if (validator === "Hiragana") {
        return TemplateManager.Util.createValidatorHiraganaWithSpace();
    }
    if (validator === "HiraganaNoSpace") {
        return TemplateManager.Util.createValidatorHiraganaNoSpace();
    }
};

export const buildTextElement = (definition: TextElementDefinition, template: DOMTemplate): FormTextElement => {
    const formElement = new FormTextElement(definition.id, template);
    setupFormElement(definition, formElement);
    setupPreSuffix(definition, formElement);
    if (definition.min !== undefined) {
        formElement.textElement.minLength = definition.min;
    }
    if (definition.max !== undefined) {
        formElement.textElement.maxLength = definition.max;
    }
    if (definition.placeholder) {
        formElement.textElement.placeholder = definition.placeholder;
    }
    if (definition.type === "number") {
        formElement.validator = buildValidator(definition.validator || "NumberOnly");
        const textElement = formElement.textElement;
        // textElement.setAttribute("type", "number");
        textElement.setAttribute("inputmode", "numeric");
        formElement.isNumerical = true;
        textElement.addEventListener("compositionend", e => {
            const selectionStart = textElement.selectionStart;
            const data = e.data;
            const charCodeOffset = "０".charCodeAt(0) - "0".charCodeAt(0);
            const insertData = data.replace(/[０-９]/g, (s) => {
                return String.fromCharCode(s.charCodeAt(0) - charCodeOffset);
            }).replace(/[^0-9]*/g, "")
            textElement.value = textElement.value.replace(/[０-９]/g, (s) => {
                return String.fromCharCode(s.charCodeAt(0) - charCodeOffset);
            }).replace(/[^0-9]*/g, "");
            const newSelectionStart = selectionStart + insertData.length - data.length;
            textElement.setSelectionRange(newSelectionStart, newSelectionStart);
        });
        textElement.addEventListener("input", (e) => {
            const selectionStart = textElement.selectionStart;
            // @ts-ignore
            const data = e.data;
            // @ts-ignore
            const inputType = e.inputType;
            if (inputType === "insertFromComposition") {
                // const charCodeOffset = "０".charCodeAt(0) - "0".charCodeAt(0);
                // const insertData = data.replace(/[０-９]/g, (s) => {
                //     return String.fromCharCode(s.charCodeAt(0) - charCodeOffset);
                // }).replace(/[^0-9]*/g, "")
                // textElement.value = textElement.value.replace(/[０-９]/g, (s) => {
                //     return String.fromCharCode(s.charCodeAt(0) - charCodeOffset);
                // }).replace(/[^0-9]*/g, "");
                // const newSelectionStart = selectionStart + insertData.length - data.length;
                // textElement.setSelectionRange(newSelectionStart, newSelectionStart);
            } else if (inputType === "insertText") {
                const insertData = data.replace(/[^0-9]*/g, "");
                textElement.value = textElement.value.replace(/[^0-9]*/g, "");
                const newSelectionStart = selectionStart + insertData.length - data.length;
                textElement.setSelectionRange(newSelectionStart, newSelectionStart);
            }
        });
    } else {
        if (definition.validator) {
            formElement.validator = buildValidator(definition.validator);
        }
        formElement.isNumerical = definition.validator === "NumberOnly";
    }
    if (definition.class) {
        formElement.fieldsElement.classList.add(definition.class);
    }
    if (definition.readonly) {
        formElement.textElement.readOnly = definition.readonly;
    }
    if (definition.initialValue) {
        formElement.value = definition.initialValue;
    }
    if (definition.fullWidth) {
        formElement.isFullWidth = definition.fullWidth;
    }
    return formElement;
}

function buildRadioElement(definition: RadioElementDefinition, template: DOMTemplate): FormRadioElement {
    const element = new FormRadioElement(definition.id, definition.options, template);
    setupFormElement(definition, element);
    return element;
}

export function buildCheckBoxElement(definition: CheckBoxElementDefinition, template: DOMTemplate): FormCheckBoxElement {
    const element = new FormCheckBoxElement(definition.id, definition.options, template);
    if (!definition.showSelectAllButtons) {
        element.selectAllButton.style.display = "none";
        element.selectAllButton.disabled = true;
        element.deselectAllButton.style.display = "none";
        element.deselectAllButton.disabled = true;
    }
    setupFormElement(definition, element);
    return element;
}

export function buildSelectElement(definition: SelectElementDefinition, template: DOMTemplate): FormSelectElement {
    const element = new FormSelectElement(definition.id, definition.options, template);
    setupFormElement(definition, element);
    setupPreSuffix(definition, element);
    if (definition.initialValue) {
        element.value = definition.initialValue;
    }
    return element;
}

function buildNumberElement(definition: NumberElementDefinition, template: DOMTemplate): FormNumberElement {
    const element = new FormNumberElement(definition.id, template);
    setupFormElement(definition, element);
    setupPreSuffix(definition, element);
    if (definition.min !== undefined) {
        element.min = definition.min;
    }
    if (definition.max !== undefined) {
        element.max = definition.max;
    }
    if (definition.initialValue) {
        element.initialValue = definition.initialValue;
    }
    return element;
}

function buildTextAreaElement(definition: TextAreaElementDefinition, template: DOMTemplate): FormTextAreaElement {
    const element = new FormTextAreaElement(definition.id, template);
    setupFormElement(definition, element);
    if (definition.placeholder) {
        element.textAreaElement.placeholder = definition.placeholder;
    }
    if (definition.maxLength) {
        element.textAreaElement.maxLength = definition.maxLength;
    }
    if (definition.initialValue) {
        element.value = definition.initialValue;
    }
    return element;
}

export const buildZipCodeElement = (definition: ZipCodeElementDefinition, template: DOMTemplate): FormZipCodeElement => {
    const element = new FormZipCodeElement(definition.id, template);
    setupFormElement(definition, element);
    return element;
}

const buildPhoneNumberElement = (definition: PhoneNumberElementDefinition, template: DOMTemplate): FormPhoneNumberElement => {
    const element = new FormPhoneNumberElement(definition.id, template);
    setupFormElement(definition, element);
    return element;
};

export function buildFieldDomElement(definition: FieldDomTemplate, template: DOMTemplate): FieldDomElement {
    const element = new FieldDomElement(definition.id, definition.domTemplate, template);
    if (definition.validatorSetup) {
        element.validator = definition.validatorSetup(element, definition);
    } else {
        element.validator = definition.validate;
    }
    element.calculateValue = definition.value;
    element.deserializeValue = definition.setValue;
    setupFormElement(definition, element);
    if (definition.manualUpdate) {
        definition.manualUpdate(element);
    }
    if (definition.setup) {
        definition.setup(element, definition);
        element.update();
    }
    if (definition.extraSetup) {
        definition.extraSetup(element, definition);
    }
    if (definition.placeholder) {
        //addressのだけ対応してもあれなので、input要素の最初にだけとりあえず突っ込むようにする
        if(element.mapKeys.length > 0){
            const first_input = element.subInput(element.mapKeys[0]);
            first_input.setAttribute("placeholder",definition.placeholder);
        }
    }
    return element;
}

export function buildFieldCompositeElement(definition: FieldObjectConcreteDefinition, rootContainer: FieldInputContainer, template: DOMTemplate): FieldCompositeElement {
    let id = definition.id;
    const index = definition.index;
    if (index !== undefined) {
        id = `${id}[${index}]`;
    }

    const element = new FieldCompositeElement(id, template);
    element.calculateValue = definition.value;
    setupFormElement(definition, element);
    const fields = definition.fields;
    for (let i = 0, l = fields.length; i < l; i++) {
        const field = fields[i];
        const subInputs = buildInput(field, rootContainer, element, template);
        subInputs.forEach(subInput => {
            element.appendFieldInput(subInput);
        });
    }
    if (definition.setup) {
        definition.setup(element, definition);
        element.update();
    }
    if (definition.extraSetup) {
        definition.extraSetup(element, definition);
    }
    return element;
}

export function createFieldInputElements(definition: ElementDefinition, rootContainer: FieldInputContainer, template: DOMTemplate): FieldInputElement {
    switch (definition.kind) {
        case "field":
            return buildFieldCompositeElement(definition, rootContainer, template);
        case "text":
            return buildTextElement(definition, template);
        case "radio":
            return buildRadioElement(definition, template);
        case "checkbox":
            return buildCheckBoxElement(definition, template);
        case "select":
            return buildSelectElement(definition, template);
        case "number":
            return buildNumberElement(definition, template);
        case "textarea":
            return buildTextAreaElement(definition, template);
        case "zipcode":
            return buildZipCodeElement(definition, template);
        case "phone_number":
            return buildPhoneNumberElement(definition, template);
        case "dom":
            return buildFieldDomElement(definition, template);
    }
    console.error(definition);
    throw new Error(`unknown kind: ${(definition as any).kind}`);
}

export const ensureArray = (data: number | string | number[] | string[]): number[] | string[] => {
    if (typeof data === "string") return [data];
    if (typeof data === "number") return [data];
    return data;
}

export const parseConditionCriteria = (condition: FieldConditionLocalCriteria): { targetId: string, targetValue: string[] | number[]} => {
    return { targetId: condition.id, targetValue: ensureArray(condition.value) };
}

export const parseCondition = (id: string, condition: ElementCondition): { targetAction: "disable" | "enable", targetPairs: { targetId: string, targetValue: string[] | number[]}[]} => {
    if (typeof condition === "string") {
        return { targetAction: "enable", targetPairs: [{ targetId: condition, targetValue: [id] }] };
    }
    const targetAction = condition.action || "enable" as const;
    if (isFieldConditionAndCriteria(condition)) {
        return { targetAction, targetPairs: condition.$and.map(c => parseConditionCriteria(c))}
    }
    return { targetAction, targetPairs: [ parseConditionCriteria(condition) ]};
}

export const notsatisfiesCondition = (targetInput: FieldInputElement, targetValue: number[] | string[]): boolean => {
    const parsed = targetInput.value;
    if (targetInput instanceof FormCheckBoxElement) {
        const values: string[] = parsed;
        return (values ? values.every(value => !(targetValue as string[]).includes(value)) : true);
    } else if (targetInput instanceof FormRadioElement) {
        return (!(targetValue as string[]).includes(parsed));
    } else if (targetInput instanceof FormSelectElement) {
        return (!(targetValue as string[]).includes(parsed));
    } else if (targetInput instanceof FormNumberElement) {
        return (!(targetValue as number[]).includes(parsed));
    } else if (targetInput instanceof FormTextElement) {
        return (!(targetValue as string[]).includes(parsed));
    }
    throw new Error("unsupported condition");
}

const setupFormCondition = (fieldInput: FieldInputElement, condition: ElementCondition, rootContainer: ReadonlyFieldInputContainer, parentContainer: ReadonlyFieldInputContainer, shouldHideDisabledField:boolean = true) => {
    const { targetAction, targetPairs } = parseCondition(fieldInput.id, condition);
    const targetInputs = targetPairs.flatMap(pair => {
        const input = findFieldInput(rootContainer, pair.targetId) || findFieldInput(parentContainer, pair.targetId)
        if (input) {
            return [input];
        }
        console.error(`targetInput not found: ${rootContainer.id}::${pair.targetId}`);
        return [];
    });
    const callback = () => {
        const flag = targetAction === "enable";
        fieldInput.disabled = targetInputs.some((targetInput, i) => notsatisfiesCondition(targetInput, targetPairs[i].targetValue)) === flag;
        if (shouldHideDisabledField) {
            fieldInput.hidden = fieldInput.disabled;
        }
    };
    targetInputs.forEach(targetInput => { targetInput.virtualInput.addEventListener("change", callback); });
    callback();
}

const postProcessSetup = (fieldInput: FieldInputElement, data: ElementDefinition, rootContainer: ReadonlyFieldInputContainer, parentContainer: ReadonlyFieldInputContainer) => {
    if (data.condition) {
        setupFormCondition(fieldInput, data.condition, rootContainer, parentContainer, true);
    }
    if (data.refer) {
        const targetToken = data.refer.split("-");
        const targetId = targetToken[0];
        const targetInput = findFieldInput(rootContainer, targetId) || findFieldInput(parentContainer, targetId);
        if (targetInput instanceof FormZipCodeElement) {
            targetInput.addReferer(fieldInput, targetToken.length === 1 ? "raw" : <ZipcodeReferenceType>targetToken[1]);
        } else {
            console.error(`invalid reference: ${data.refer}`);
        }
    }
}

const postProcessSetupFieldCompositeElementCondition = (container: FieldCompositeElement, data: FieldObjectConcreteDefinition, form: DigitalForm) => {
    data.fields.forEach(definition => {
        const input = findFieldInput(container, definition.id);
        if (definition.kind === "field") {
            postProcessSetupFieldCompositeElementCondition(input as FieldCompositeElement, definition, form);
        }
        postProcessSetup(input, definition, form, container);
    });
}

const postProcessFieldBox = (container: FieldBox, data: DigitalFormBoxDefinition, form: DigitalForm) => {
    if (data.condition) {
        setupFormCondition(container, data.condition, form, form, false);
    }
    data.elements.forEach(definition => {
        const input = findFieldInput(container, definition.id);
        if (definition.kind === "field") {
            postProcessSetupFieldCompositeElementCondition(input as FieldCompositeElement, definition, form);
        }
        postProcessSetup(input, definition, form, container);
    });
}

const postProcessFormSetup = (form: DigitalForm, data: DigitalFormDefinition) => {
    data.boxes.forEach(definition => postProcessFieldBox(findFieldInput(form, definition.id) as FieldBox, definition, form));
    if (data.extraSetup) {
        data.extraSetup(form);
    }
}

export type FormInputChangeInterceptor = (path: FormElementStringPath, value: any) => void;
const formInputChangeInterceptors: Set<FormInputChangeInterceptor> = new Set();

export const registerFormInputChangeInsterceptor = (interceptor: FormInputChangeInterceptor) => {
    if (formInputChangeInterceptors.has(interceptor)) throw new Error(`interceptor is already registered`);
    formInputChangeInterceptors.add(interceptor);
}

export const removeFormInputChangeInterceptor = (interceptor: FormInputChangeInterceptor) => {
    if (!formInputChangeInterceptors.has(interceptor)) throw new Error(`interceptor is not registered`);
    formInputChangeInterceptors.delete(interceptor);
}

export function buildInput(field: ElementDefinition, rootContainer: FieldInputContainer, parentContainer: FieldInputContainer, template: DOMTemplate): FieldInputElement[] {
    let min = 1;
    let max = 1;
    let variableNumber = false;
    if (field.cardinality) {
        if (typeof field.cardinality === "number") {
            min = field.cardinality;
            max = field.cardinality;
        } else if (typeof field.cardinality === "string") {
            const bind = field.cardinality;
            const fieldInput = findFieldInput(rootContainer, bind);
            const value = fieldInput.virtualInput.value;
            if (value && value !== "undefined") {
                min = JSON.parse(value);
            } else {
                min = 1;
            }

            max = min;
            const callback = () => {
                const inputs = buildInput(field, rootContainer, rootContainer, template);
                rootContainer.updateFieldInput(inputs[0], field.id);
                fieldInput.virtualInput.removeEventListener("change", callback);
            };
            fieldInput.virtualInput.addEventListener("change", callback);
            variableNumber = true;
        } else {
            if (field.cardinality.min) {
                min = field.cardinality.min;
            }
            if (field.cardinality.max) {
                max = field.cardinality.max;
            }
        }
    }
    const definition = (() => {
        if (!variableNumber) return field;
        const fieldDefinition: FieldObjectConcreteDefinition = {
            kind: "field",
            id: field.id,
            fields: []
        };
        for (let j = 0; j < min; j++) {
            const elementField = Object.assign({}, field);
            delete elementField.cardinality;
            elementField.index = j;
            fieldDefinition.fields.push(elementField);
        }
        return fieldDefinition;
    })();
    const fieldInput = createFieldInputElements(definition, rootContainer, template);
    if (field.hidden) {
        fieldInput.isGhostElement = true;
    }
    fieldInput.virtualInput.addEventListener("change", () => {
        formInputChangeInterceptors.forEach(interceptor => {
            interceptor([rootContainer.id, field.id], fieldInput.value);
        })
    });
    return [fieldInput];
}

export function appendFieldBox(form: DigitalForm, data: DigitalFormBoxDefinition, template: DOMTemplate) {
    const box = new FieldBox(data.id, template);
    if (data.title) {
        box.title = data.title;
    }
    if (data.required) {
        box.isRequired = data.required;
    }
    if (data.description) {
        box.description = data.description;
    }
    if (data.hidden) {
        box.hidden = data.hidden;
    }
    form.appendBox(box);
    data.elements.forEach(definition => buildInput(definition, form, form, template).forEach(subInput => box.appendFieldInput(subInput)));
}


const postProcessSetupRule = (fieldInput: FieldInputElement, data: ElementDefinition, form: DigitalForm, parentContainer: ReadonlyFieldInputContainer) => {
    if (data.rule) {
        const targetResolver: TargetResolver = (selector: FieldSelector) => [form, typeof selector === "string" ? selector : selector.fieldID];
        switch (data.rule.action) {
            case "sync": {
                const formSyncRule: FormSyncRule<"local"> = {
                    action: "sync",
                    condition: data.rule.condition,
                    sync: [
                        [...data.rule.sync, data.id]
                    ]
                };
                const formSyncGlobalRule = convertLocalRuleToGlobalRule(formSyncRule, form.id) as FormSyncRule<"global">;
                setupSync(formSyncGlobalRule, targetResolver)
                break;
            }
            case "calculation": {
                const formCalculationRule: FormCalculationRule<"local"> = {
                    action: "calculation",
                    condition: data.rule.condition,
                    target: data.id,
                    returnType: data.rule.returnType,
                    expression: data.rule.expression
                };
                const formCalculationGlobalRule = convertLocalRuleToGlobalRule(formCalculationRule, form.id) as FormCalculationRule<"global">;
                setupCalculationSync(formCalculationGlobalRule, targetResolver);
                break;
            }
        }
    }
}

const postProcessSetupFieldCompositeElementRule = (container: FieldCompositeElement, data: FieldObjectConcreteDefinition, form: DigitalForm) => {
    data.fields.forEach(definition => {
        const input = findFieldInput(container, definition.id);
        if (definition.kind === "field") {
            postProcessSetupFieldCompositeElementRule(input as FieldCompositeElement, definition, form);
        }
        postProcessSetupRule(input, definition, form, container);
    });
}

const postProcessFieldBoxRule = (container: FieldBox, data: DigitalFormBoxDefinition, form: DigitalForm) => {
    data.elements.forEach(definition => {
        const input = findFieldInput(container, definition.id);
        if (definition.kind === "field") {
            postProcessSetupFieldCompositeElementRule(input as FieldCompositeElement, definition, form);
        }
        postProcessSetupRule(input, definition, form, container);
    });
}

export function setupPostProcessRule(form: DigitalForm, data: DigitalFormDefinition) {
    data.boxes.forEach(definition => postProcessFieldBoxRule(findFieldInput(form, definition.id) as FieldBox, definition, form));
}

export function buildDigitalForm(data: DigitalFormDefinition, template: DOMTemplate): DigitalForm {
    const form = new DigitalForm(data.id, template);
    if (data.title) {
        form.title = data.title;
    }
    data.boxes.forEach(definition => appendFieldBox(form, definition, template));
    if (form.numberOfBoxes > 0) {
        form.boxAtIndex(0).open();
    }
    postProcessFormSetup(form, data);
    return form;
}

export const conditionalSync = (conditionPath: FormElementPath, target: any, ...paths: FormElementPath[]) => {
    registerSyncRuleCommand(["conditionalSync", toFormElementStringPath(conditionPath), target, toFormElementStringPaths(paths)]);
};

export const sync = (...paths: FormElementPath[]) => {
    registerSyncRuleCommand(["sync", toFormElementStringPaths(paths)]);
};

export const calculateSync = (sourcePaths: FormElementPath[], destinationPath: FormElementPath, calculate: (xs: FieldInputElement[]) => string, returnType: "number" | "string", verbose?: boolean) => {
    registerSyncRuleCommand(["calculateSync", toFormElementStringPaths(sourcePaths), toFormElementStringPath(destinationPath), calculate, returnType]);
}

const ruleSyncTemplate: { [keys: string]: FormGlobalRule } = {};

export const defineRuleTemplate = (id: string, template: FormGlobalRule) => {
    ruleSyncTemplate[id] = template;
}

let ruleEngine: DigitalFormRuleEngine;

export const setRuleEngine = (engine: DigitalFormRuleEngine) => {
    ruleEngine = engine;
}

const registerSyncRuleCommand = (command: SyncRuleCommand) => {
    ruleEngine.registerCommand(command);
}

export const getRegisteredSyncRuleCommands = () => ruleEngine.registeredCommands();


type TargetResolver = (selector: FieldSelector) => FormElementPath;

const setupSync = (rule: FormSyncRule<"global">, resolveTarget: TargetResolver, verbose?: boolean) => {
    rule.sync.forEach(syncGroup => {
        if (rule.condition) {
            conditionalSync(resolveTarget(rule.condition.target), rule.condition.value,
                ...syncGroup.map(syncRule => resolveTarget(syncRule))
            );
        } else {
            sync(...syncGroup.map(syncRule => resolveTarget(syncRule)));
        }
    });
}

const setupCalculationSync = (rule: FormCalculationRule<"global">, resolveTarget: TargetResolver, verbose?: boolean) => {
    const inputs = rule.expression.inputs.slice();
    const hasCondition = rule.condition ? true : false;
    if (hasCondition) {
        inputs.push(rule.condition.target);
    }
    calculateSync(inputs.map(target => resolveTarget(target)), resolveTarget(rule.target), xs => {
        const variableDefinitions = xs.map((x, i) => {
            const value = x.value as string | null;
            if (x instanceof FormTextElement && x.isNumerical) {
                if (!value) return `var $${i}=0;\n`;
                return `var $${i}=${value.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))};\n`
            } else {
                return `var $${i}=${JSON.stringify(value)};\n`
            }
        }).join("");
        const resolvedFormat = `${variableDefinitions}${hasCondition ? `$${inputs.length - 1} === ${JSON.stringify(rule.condition.value)} ? undefined : null('canceled');\n` : ""}${rule.expression.format}`;
        const result = eval(resolvedFormat);
        if (rule.returnType === "number") {
            return result;
        }
        return String(result);
    }, rule.returnType || "string", verbose);
}

const convertTarget = (selector: FieldSelector, formMap: FormMap, defaultID?: string): FormElementPath => {
    if (typeof selector === "string") {
        return [formMap[defaultID], selector];
    } else {
        return [formMap[selector.formID], selector.fieldID];
    }
}

export const ruleSync = (rules: FormGlobalRule[], formMap: FormMap, defaultID?: string, verbose?: boolean) => {
    if (!ruleEngine) {
        throw new Error(`rule engine is not set`);
    }
    const _convertTarget = (selector: FieldSelector) => {
        return convertTarget(selector, formMap, defaultID);
    }
    rules.forEach(rule => {
        switch (rule.action) {
            case "sync":
                setupSync(rule, _convertTarget, verbose);
                return;
            case "addressConcat":
                rule.concat.forEach(addressConcatGroup => {
                    const [target, address, chouban, building] = addressConcatGroup.map(target => _convertTarget(target));
                    addressSync(target, address, chouban, building);
                });
                return;
            case "label":
                return;
            case "calculation":
                try {
                    setupCalculationSync(rule, _convertTarget, verbose);
                } catch(e) {
                }
                return;
            case "templateDefinition":
                defineRuleTemplate(rule.id, rule.template);
                return;
            case "template": {
                const resolvedRule = ruleSyncTemplate[rule.template];
                if (!resolvedRule) throw new Error(`rule template not found: ${rule.template}`);
                
                let resolvedRuleString = JSON.stringify(resolvedRule);
                Object.entries(rule.args).forEach(entry => {
                    resolvedRuleString = resolvedRuleString.replace(new RegExp(`\\$\\(${entry[0]}\\)`, "g"), entry[1]);
                });
                const derivedRule = JSON.parse(resolvedRuleString);
                ruleSync([derivedRule], formMap, defaultID);
                return;
            }
            case "array": {
                ruleSync(rule.items, formMap, defaultID);
                return;
            }
            case "show": {
                const conditionPath = _convertTarget(rule.condition.target);
                const targets = Array.isArray(rule.target) ? rule.target : [rule.target];
                const paths = targets.map(t => _convertTarget(t));
                registerSyncRuleCommand(["show", toFormElementStringPath(conditionPath), rule.condition.value, toFormElementStringPaths(paths)]);
                return;
            }
        }
        const _never: never = rule;
        throw new Error(`invalid rule: ${JSON.stringify(rule, null, 2)}`);
    });
}

export const addressSync = (
    targetPath: FormElementPath, 
    address: FormElementPath, 
    chouban: FormElementPath, 
    building: FormElementPath
) => {
    registerSyncRuleCommand(["addressSync", toFormElementStringPath(targetPath), toFormElementStringPath(address), toFormElementStringPath(chouban), toFormElementStringPath(building)]);
};

export const addressSync2 = (
    targetPath: FormElementPath, 
    address: FormElementPath, 
    chouban: FormElementPath
) => {
    registerSyncRuleCommand(["addressSync2", toFormElementStringPath(targetPath), toFormElementStringPath(address), toFormElementStringPath(chouban)]);
};

const hasRole = (definition: DigitalFormDefinition, role: string): boolean => {
    return getPersonaByRole(definition, role) !== undefined;
}

const getRoles = (definition: DigitalFormDefinition): string[] => {
    return definition.persona ? Object.keys(definition.persona) : [];
}

const getRolesFromMap = (map: FormDefinitionMap): string[] => {
    return [...new Set(Object.values(map).flatMap(definition => {
        return getRoles(definition);
    }))];
};

const getPersonaByRole = (definition: DigitalFormDefinition, role: string): Persona => {
    return definition.persona ? definition.persona[role] : undefined;
};

const getMutualPersonaProperties = (personaList: Persona[]): string[] => {
    return [...new Set(personaList.flatMap(persona => Object.keys(persona)))];
};

const getMutualPersonaPropertiesForRole = (map: FormDefinitionMap, role: string): string[] => {
    const personaList = Object.values(map).flatMap(definition => {
        const persona = getPersonaByRole(definition, role);
        return persona !== undefined ? [persona] : [];
    });
    return getMutualPersonaProperties(personaList);
};

const linkPersonaByRole = (formMap: FormMap, definitionMap: FormDefinitionMap, role: string) => {
    const formIds = Object.keys(formMap);
    getMutualPersonaPropertiesForRole(definitionMap, role).forEach(property => {
        const linkProperties = formIds.flatMap(id => {
            const form = formMap[id];
            const definition = definitionMap[id];
            if (!hasRole(definition, role)) {
                return [];
            }
            return [[form, definition.persona[role][property]] as FormElementPath];
        });
        sync(...linkProperties);
    });
};

export const linkPersona = (formMap: FormMap, definitionMap: FormDefinitionMap) => {
    const roles = getRolesFromMap(definitionMap);
    roles.forEach(role => {
        linkPersonaByRole(formMap, definitionMap, role);
    });
};