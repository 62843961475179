import { AnalogFormBindAttribute, isAnalogFormValueBindAttribute } from "../editor/AnalogFormBindAttribute";
import { AnalogFormRenderingAttribute, AnalogFormSimpleRenderingAttribute } from "../editor/AnalogFormRenderingAttribute";
import { AnalogFormColumnDefinition } from "../editor/AnalogFormWysiwygEditor";

interface FormRenderingSettingEjs {
    path: string,
    numberOfPages: number;
    extraPages?: ExtraPageDefinition[]
}

export type FormRenderingBind = AnalogFormColumnDefinition<AnalogFormRenderingAttribute & AnalogFormBindAttribute>;

export interface FormRenderingSettingJSON {
    kind: "json";
    backgroundImage: string;
    binds: FormRenderingBind[];
    width: number;
    height: number;
    numberOfPages: number;
    extraPages?: ExtraPageDefinition[]
}

export const TargetPrintMode = {
    Default: 0,
    Form: 1,
    QR: 2
} as const;

export interface ExtraPageDefinition {
    kind: "html" | "image";
    url: string;
}

export interface AppExtraPageDefinition {
    kind: "html" | "image";
    url: string;
    targetPrintMode?: number;
}

export type FormRenderingSetting = FormRenderingSettingEjs | FormRenderingSettingJSON;

const isRenderingSettingEjs = (rendering: FormRenderingSetting): rendering is FormRenderingSettingEjs => {
    return !(rendering as any).kind;
}

const classForBind = (bind: AnalogFormSimpleRenderingAttribute) => {
    switch (bind.textAlign) {
        case "left":
            switch (bind.verticalAlign) {
                case "top":
                    return null;
                case "middle":
                    return "align-center-left";
            }
            break;
        case "right":
            switch (bind.verticalAlign) {
                case "top":
                    return "align-right2";
                case "middle":
                    return "align-center-right";
            }
            break;
        case "center":
            switch (bind.verticalAlign) {
                case "top":
                    return "align-center-top";
                case "middle":
                    return "align-center-middle";
            }
            break;
    }
    return "";
}

const wrapConditionalBind = (bind: FormRenderingBind, html: string): string => {
    if (!isAnalogFormValueBindAttribute(bind)) {
        return html;
    }
    if (!bind.condition) return html;
    return `<span data-bind="${bind.condition}" data-target-value="${bind.targetValue}" data-target-class="show" data-type="radio" class="conditionalBind">${html}</span>`;
}

export const buildSpanHTML = (bind: FormRenderingBind): string => {
    switch (bind.kind) {
        case "simple": {
            const targetAlignClass = classForBind(bind);
            const targetClass = `text-input${ targetAlignClass ? ` ${targetAlignClass}` : ""}`;
            return `<span class="${targetClass}" 
style="left: ${bind.frame.x}px; top: ${bind.frame.y}px; width: ${bind.frame.width}px; height: ${bind.frame.height}px;" data-bind="${bind.questionPath}" data-font-initial-size="${bind.fontSize}" data-auto-font-scale="${String(bind.autoScale)}" data-enable-line-break="${String(bind.enableLineBreak || false)}"></span>`;
        }
        case "check": {
            const dataType = bind.bindType === "checkbox" ? "checkbox" : "radio";
            return `<span class="select-input"
style="left: ${bind.frame.x}px; top: ${bind.frame.y}px; width: ${bind.frame.width}px; height: ${bind.frame.height}px;" data-bind="${bind.questionPath}" data-type="${dataType}" data-target-class="${bind.targetClass}" data-target-value="${bind.targetValue}" data-is-json="false"></span>`;
        }
        case "char": {
            return `<span class="select-input"
style="left: ${bind.frame.x}px; top: ${bind.frame.y}px; width: ${bind.frame.width}px; height: ${bind.frame.height}px;" data-bind="${bind.questionPath}" data-type="char" data-offset="${bind.offset}" data-digit-align="${bind.digitAlign}" data-font-initial-size="${bind.fontSize}"></span>`;
        }
        case "image": {
            return `<span class="text-input px26 align-center2" 
style="left: ${bind.frame.x}px; top: ${bind.frame.y}px; width: ${bind.frame.width}px; height: ${bind.frame.height}px;" data-bind="${bind.questionPath}" data-type="image"></span>`;
        }
    }
    const _never: never = bind;
};

export const buildRenderingFrame = (rendering: FormRenderingSetting): HTMLIFrameElement => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("sandbox", "allow-modals allow-same-origin allow-scripts");
    iframe.setAttribute("style", "display: none;");
    iframe.setAttribute("data-num-pages", String(rendering.numberOfPages));
    if (isRenderingSettingEjs(rendering)) {
        iframe.src = rendering.path;
    } else {
        iframe.srcdoc = `<!DOCTYPE html><html lang="ja">
    <head>
        <meta charset="utf-8" />
        <link rel="stylesheet" href="./application.css">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin> 
        <link href="https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap" rel="stylesheet">
    </head>
    <body>
        <main ${rendering.width > rendering.height ? `class="horizontal" ` : ""}style="background-image:url('${rendering.backgroundImage}');background-size: ${rendering.width}px ${rendering.height}px;width:${rendering.width}px;height:${rendering.height}px;">
${rendering.binds.map(bind => wrapConditionalBind(bind, buildSpanHTML(bind))).join("")}</main></body>
</html>`;
    }
    return iframe;
}

export const shouldIncludeInPrintMode = (printMode: typeof TargetPrintMode.Form | typeof TargetPrintMode.QR, targetPrintMode: number = TargetPrintMode.Default) => {
    switch (printMode) {
        case TargetPrintMode.Form:
            return targetPrintMode === undefined || targetPrintMode === TargetPrintMode.Default || ((targetPrintMode & TargetPrintMode.Form) === TargetPrintMode.Form);
        case TargetPrintMode.QR:
            return (targetPrintMode & TargetPrintMode.QR) === TargetPrintMode.QR;
    }
}