import { FieldSelectorType, GlobalFieldSelector } from "../condition";
import { FormAddressConcatRule } from "./addressConcat";
import { FormArrayRule } from "./array";
import { FormCalculationRule } from "./calculation";
import { FormLabelRule } from "./label";
import { FormShowRule } from "./show";
import { FormSyncRule } from "./sync";
import { FormTemplateReferenceRule } from "./template";
import { FormTemplateDefinitionRule } from "./templateDefinition";

type FormRuleTypes<T extends FieldSelectorType> = [
  FormSyncRule<T>,
  FormLabelRule<T>,
  FormAddressConcatRule<T>,
  FormCalculationRule<T>,
  FormTemplateDefinitionRule<T>,
  FormTemplateReferenceRule<T>,
  FormArrayRule<T>,
  FormShowRule<T>
];

type FormConcreteRuleTypes<T extends FieldSelectorType> = [
  FormSyncRule<T>,
  FormLabelRule<T>,
  FormAddressConcatRule<T>,
  FormCalculationRule<T>,
  FormShowRule<T>
];

export type FormRule<T extends FieldSelectorType> = FormRuleTypes<T>[number];
export type FormConcreteRule<T extends FieldSelectorType> = FormConcreteRuleTypes<T>[number];

type GetFormRuleKind<T> = T extends { action: infer K } ? K : never;

type GetFirstItemAction<R> = R extends [{ action: infer X }, ...unknown[]] ? X : never;
type Head<R> = R extends [infer X, ...unknown[]] ? X : never;
type Tail<R> = R extends [unknown, ...infer X] ? X : never;
type GetFormRule<T, R> = T extends any ? (GetFirstItemAction<R> extends T ? Head<R> : GetFormRule<T, Tail<R>>) : never;

export type GetFormLocalRule<T> = GetFormRule<T, FormRuleTypes<"local">>;
export type GetFormGlobalRule<T> = GetFormRule<T, FormRuleTypes<"global">>;

export type FormGlobalRule = FormRule<"global">;
export type FormLocalRule = FormRule<"local">;

export type FormConcreteGlobalRule = FormConcreteRuleTypes<"global">[number];
export type FormConcreteLocalRule = FormConcreteRuleTypes<"local">[number];

type FormRuleKinds = GetFormRuleKind<FormRuleTypes<"global" | "local">[number]>;

const ensureGlobalFieldSelector = (selector: string | GlobalFieldSelector, formID: string): GlobalFieldSelector => {
  if (typeof selector !== "string") return selector;
  return { formID, fieldID: selector }
} 

export const convertLocalRuleToGlobalRule = <T extends FormRuleKinds>(rule: GetFormLocalRule<T>, formID: string): GetFormGlobalRule<typeof rule.action> => {
  switch (rule.action) {
    case "label":
      return rule;
    case "template":
      return {
        action: rule.action,
        description: rule.description,
        template: rule.template,
        args: Object.assign({formID}, rule.args)
      };
    case "addressConcat":
      return {
        action: rule.action,
        description: rule.description,
        concat: rule.concat.map(params => {
          return [
            ensureGlobalFieldSelector(params[0], formID),
            ensureGlobalFieldSelector(params[1], formID),
            ensureGlobalFieldSelector(params[2], formID),
            ensureGlobalFieldSelector(params[3], formID),
          ]
        })
      };
    case "sync":
      return {
        action: rule.action,
        condition: rule.condition ? {
          target: ensureGlobalFieldSelector(rule.condition.target, formID),
          value: rule.condition.value
        } : undefined,
        description: rule.description,
        sync: rule.sync.map(params => {
          return params.map(fieldID => ensureGlobalFieldSelector(fieldID, formID))
        })
      };
    case "show":
      return {
        action: rule.action,
        description: rule.description,
        condition: {
          target: ensureGlobalFieldSelector(rule.condition.target, formID),
          value: rule.condition.value
        },
        target: Array.isArray(rule.target) ? rule.target.map(fieldID => ensureGlobalFieldSelector(fieldID, formID)) : ensureGlobalFieldSelector(rule.target, formID)
      }
    case "calculation":
      return {
        action: rule.action,
        description: rule.description,
        condition: rule.condition ? {
          target: ensureGlobalFieldSelector(rule.condition.target, formID),
          value: rule.condition.value
        } : undefined,
        returnType: rule.returnType,
        target: ensureGlobalFieldSelector(rule.target, formID),
        expression: {
          format: rule.expression.format,
          inputs: rule.expression.inputs.map(input => ensureGlobalFieldSelector(input, formID))
        }
      }
    case "templateDefinition":
      return {
        action: rule.action,
        description: rule.description,
        id: rule.id,
        template: convertLocalRuleToGlobalRule(rule.template, formID)
      }
    case "array":
      return {
        action: rule.action,
        description: rule.description,
        items: rule.items.map(item => convertLocalRuleToGlobalRule(item, formID))
      };
    default:
      const _localRule: never = rule;
  }
}

export const convertGlobalRuleToLocalRule = <T extends FormRuleKinds>(rule: GetFormGlobalRule<T>): GetFormLocalRule<typeof rule.action> => {
  switch (rule.action) {
    case "label":
      return rule;
    case "template":
      return {
        action: rule.action,
        description: rule.description,
        template: rule.template,
        args: Object.fromEntries(Object.entries(rule.args).filter(entry => entry[0] !== "formID"))
      };
    case "addressConcat":
      return {
        action: rule.action,
        description: rule.description,
        concat: rule.concat.map(params => {
          return [
            params[0].fieldID,
            params[1].fieldID,
            params[2].fieldID,
            params[3].fieldID,
          ]
        })
      };
    case "sync":
      return {
        action: rule.action,
        condition: rule.condition ? {
          target: rule.condition.target.fieldID,
          value: rule.condition.value
        } : undefined,
        description: rule.description,
        sync: rule.sync.map(params => {
          return params.map(param => param.fieldID)
        })
      };
    case "show":
      return {
        action: rule.action,
        description: rule.description,
        condition: {
          target: rule.condition.target.fieldID,
          value: rule.condition.value
        },
        target: Array.isArray(rule.target) ? rule.target.map(target => target.fieldID) : rule.target.fieldID
      }
    case "calculation":
      return {
        action: rule.action,
        description: rule.description,
        condition: rule.condition ? {
          target: rule.condition.target.fieldID,
          value: rule.condition.value
        } : undefined,
        returnType: rule.returnType,
        target: rule.target.fieldID,
        expression: {
          format: rule.expression.format,
          inputs: rule.expression.inputs.map(input => input.fieldID)
        }
      }
    case "templateDefinition":
      return {
        action: rule.action,
        description: rule.description,
        id: rule.id,
        template: convertGlobalRuleToLocalRule(rule.template)
      }
    case "array":
      return {
        action: rule.action,
        description: rule.description,
        items: rule.items.map(item => convertGlobalRuleToLocalRule(item))
      };
    default:
      const _localRule: never = rule;
  }
}