export type PropertyValidationErrors<T> = {
  [P in keyof T]?: string[];
}

export type ValidationErrors<T> = {
  properties?: PropertyValidationErrors<T>,
  all?: string[];
}

export type ValidatorDefinition<T> = {
  properties?: {
    [P in keyof T]: (value: T[P]) => string[];
  },
  all?: (value: T) => string[];
}

export const validate = <T>(value: T, validator: ValidatorDefinition<T>): ValidationErrors<T> => {
  let errors: ValidationErrors<T> = {};
  let properties: PropertyValidationErrors<T> = {};
  for (let i in value) {
    const e = validator.properties[i](value[i]);
    if (e.length > 0) {
      properties[i] = e;
    }
  }
  if (Object.keys(properties).length > 0) {
    errors.properties = properties;
  }
  if (validator.all) {
    const e = validator.all(value);
    if (e.length > 0) {
      errors.all = e;
    }
  }
  return errors;
};