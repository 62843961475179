export interface FieldConditionLocalCriteria {
    id: string,
    value: string | string[] | number | number[]
}

export type GlobalFieldSelector = {
    formID: string;
    fieldID: string;
}

export type FieldSelectorMap = {
    local: string;
    global: GlobalFieldSelector;
}

export type FieldSelectorType = keyof FieldSelectorMap;

export type FieldSelector = string | GlobalFieldSelector;

export interface FieldConditionGlobalCriteria {
    target: FieldSelector;
    value: string | string[] | number | number[];
}

export interface FieldConditionCriteria<T extends FieldSelectorType> {
    target: FieldSelectorMap[T];
    value: string | string[] | number | number[];
}

export interface FieldConditionAndCriteria {
    operator: "and",
    $and: FieldConditionLocalCriteria[]
}

export type FieldCondition = FieldConditionLocalCriteria | FieldConditionAndCriteria;

export const isFieldConditionAndCriteria = (condition: FieldCondition): condition is FieldConditionAndCriteria => {
    return (condition as FieldConditionAndCriteria).operator === "and";
}

export type FieldConditionObject = { action?: "disable"} & FieldCondition;

export type ElementCondition = string | FieldConditionObject;