import { ensureArray, notsatisfiesCondition } from "../../digitalForm";
import { DigitalForm } from "../DigitalForm";
import { FieldInputElement } from "../Element/FieldInputElement";
import { SyncRuleCommand } from "../ruleCommand/command";
import { FormElementStringPath, toFormElementPath, toFormElementPaths } from "../ruleCommand/FormElementStringPath";
import { DigitalFormRuleEngine } from "./DigitalFormRuleEngine";
import { convertPathsToElements, FormElementPath, resolvePath } from "./FormElementPath";
import { createSyncHandler, SyncHandler } from "./SyncHandler";

const createAddressSyncHandler = (paths: FormElementPath[]): SyncHandler => {
  const elements = convertPathsToElements(paths);
  let syncing = false;
  const context = {
    elements,
    exec: (target: FieldInputElement) => {
      if (syncing) return;
      syncing = true;
      let total = "";
      if (elements[0].value) total += elements[0].value;
      if (elements[1].value) total += elements[1].value;
      if (elements[2].value) total += `　${elements[2].value}`;
      target.value = total;
      // target.value = `${elements[0].value}${elements[1].value}　${elements[2].value}`;
      syncing = false;
    }
  };
  return context;
}

const setupSync = (handler: SyncHandler, target: FieldInputElement) => {
  handler.elements.forEach(e => {
    e.virtualInput.addEventListener("change", () => {
      handler.exec(target);
    })
  })
}

const createAddressSyncHandler2 = (paths: FormElementPath[]): SyncHandler => {
  const elements = convertPathsToElements(paths);
  let syncing = false;
  const context = {
    elements,
    exec: (target: FieldInputElement) => {
      if (syncing) return;
      syncing = true;
      let total = "";
      if (elements[0].value) total += elements[0].value;
      if (elements[1].value) total += elements[1].value;
      target.value = total;
      // target.value = `${elements[0].value}${elements[1].value}　${elements[2].value}`;
      syncing = false;
    }
  };
  return context;
}

export class EventListenerBaseRuleEngine implements DigitalFormRuleEngine {
  private formMap: { [keys: string]: DigitalForm };
  private commands: SyncRuleCommand[] = [];

  public constructor(map: { [keys: string ]: DigitalForm }) {
    this.formMap = map;
  }

  toFormElementPath(path: FormElementStringPath): FormElementPath {
    return toFormElementPath(this.formMap, path);
  }

  toFormElementPaths(paths: readonly FormElementStringPath[]): FormElementPath[] {
    return toFormElementPaths(this.formMap, paths);
  }

  registerCommand(command: SyncRuleCommand): void {
    switch (command[0]) {
      case "sync": {
        const paths = this.toFormElementPaths(command[1]);
        const handler = createSyncHandler(paths);
        this.commands.push(command);
        if (handler.elements.length < 2) return;
        handler.elements.forEach(e => {
          e.virtualInput.addEventListener("change", () => {
            handler.exec(e);
          });
        });
        return;
      }
      case "conditionalSync": {
        const conditionField = resolvePath(this.toFormElementPath(command[1]));
        if (!conditionField) return;
        this.commands.push(command);
        const paths = this.toFormElementPaths(command[3]);
        const handler = createSyncHandler(paths);
        if (handler.elements.length < 2) return;
        const target = command[2];
        const targetValues: any[] = (() => {
          if (typeof target === "string") return [target];
          return target as any[];
        })();
        conditionField.virtualInput.addEventListener("change", () => {
          if (targetValues.includes(conditionField.value)) {
            handler.exec(handler.elements[0]);
          }
        });
        handler.elements.forEach(e => {
          e.virtualInput.addEventListener("change", () => {
            if (!targetValues.includes(conditionField.value)) {
              return;
            }
            handler.exec(e);
          });
        });
        return;
      }
      case "show": {
        const conditionInput = resolvePath(this.toFormElementPath(command[1]));
        if (!conditionInput) return;
        this.commands.push(command);
        const paths = this.toFormElementPaths(command[3]);
        paths.forEach(path => {
          const fieldInput = resolvePath(path);
          if (!fieldInput) return;
          const callback = () => {
            const flag = true;
            fieldInput.disabled = notsatisfiesCondition(conditionInput, ensureArray(command[2])) === flag;
            fieldInput.hidden = fieldInput.disabled;
          };
          conditionInput.virtualInput.addEventListener("change", callback);
          callback();
        })
        return;
      }
      case "addressSync": {
        const [address, chouban, building] = [command[2], command[3], command[4]].map(path => this.toFormElementPath(path));
        const handler = createAddressSyncHandler([address, chouban, building]);
        const targetField = resolvePath(this.toFormElementPath(command[1]));
        if (!targetField) return;
        this.commands.push(command);
        setupSync(handler, targetField);
        return;
      }
      case "addressSync2": {
        const [address, chouban] = [command[2], command[3]].map(path => this.toFormElementPath(path));
        const handler = createAddressSyncHandler2([address, chouban]);
        const targetField = resolvePath(this.toFormElementPath(command[1]));
        if (!targetField) return;
        this.commands.push(command);
        setupSync(handler, targetField);
        return;
      }
      case "calculateSync": {
        const verbose = false;
        const sources = this.toFormElementPaths(command[1]).map(sourcePath => {
          const input = resolvePath(sourcePath);
          if (!input) throw new Error(`source not found: ${(sourcePath[0] ? sourcePath[0].id : null)}::${sourcePath[1]}`);
          return input;
        });
        const destinationPath = this.toFormElementPath(command[2]);
        const destination = resolvePath(destinationPath);
        if (!destination) throw new Error(`destination not found: ${(destinationPath[0] ? destinationPath[0].id : null)}::${destinationPath[1]}`);
        this.commands.push(command);
        let handle = null;
        const update = () => {
          if (handle) {
            clearTimeout(handle);
          }
          handle = setTimeout(() => {
            try {
              destination.value = command[3](sources);
            } catch (e) {
              if (verbose) {
                console.error(e);
              }
            }
          }, 0);
        };
        sources.filter(e => e !== destination).forEach(source => {
          source.virtualInput.addEventListener("change", () => {
            update();
          });
        });
        destination.value = command[4] === "number" ? 0 : "0";
        update();
        return;
      }
    }
    const _never: never = command;
    throw new Error(`invalid command: ${_never}`);
  }

  registeredCommands(): SyncRuleCommand[] {
    return this.commands.slice();
  }
}