import { ConfirmPrintingResultType } from "./ConfirmPrintingResultType";
import * as Bowser from "bowser/bundled";

// main.ts側でここの判定変わった時の保守がめんどい
const browser = Bowser.getParser(window.navigator.userAgent);
const browserInfo = browser.getBrowser();
const isMobile = browser.parsePlatform().type === "mobile";
const isiPad = browserInfo.name === "Safari" && typeof document.ontouchstart !== 'undefined' && browser.parsePlatform().type !== 'mobile';
export class Notifier {

    public readonly alertbox: HTMLElement;
    public readonly lightbox: HTMLElement;
    public readonly button: HTMLElement;
    public readonly cancelButton: HTMLElement;
    public readonly canvas: HTMLCanvasElement;
    public readonly scrollArea: HTMLElement;
    private scroll: boolean = false;
    private callback?: (boolean) => void;

    private constructor(message: string, template: HTMLElement, callback: (boolean) => void = null, scroll: boolean = false) {
        this.alertbox = template.cloneNode(true) as HTMLElement;
        if(!scroll) this.alertbox.addEventListener('touchmove', this.preventScroll, false);

        this.callback = callback;

        const messagebox = this.alertbox.querySelector('p');
        messagebox.innerHTML = message;

        this.lightbox = document.createElement('div');
        this.lightbox.setAttribute('class', 'lightbox');
        this.lightbox.addEventListener('touchmove', this.preventScroll, false);

        this.button = this.alertbox.querySelector('.okButton');
        if (this.button) {
            this.button.addEventListener('click', (e) => {
                const callback = this.callback;
                this.callback = null;
                this.close();
                if (callback) { callback(true); }
            });
        }

        const cancelButton = this.alertbox.querySelector('.cancelButton');
        if (cancelButton) {
            cancelButton.addEventListener('click', e => {
                const callback = this.callback;
                this.callback = null;
                this.close();
                if (callback) { callback(false); }
            });
        }
        if(scroll){
            const h1 = this.alertbox.querySelector('h1');
            if (h1) {
                h1.addEventListener('touchmove',this.preventScroll,false);
            }
            const okButton = this.alertbox.querySelector('.okButton');
            okButton.addEventListener('touchmove',this.preventScroll,false);
        }
        document.body.insertBefore(this.lightbox, document.body.firstChild);
        document.body.appendChild(this.alertbox);
        
        //問題はモバイルだとスクロール上じゃない？ってのとマウスが微妙なくらいかな。

        this.scrollArea = this.alertbox.querySelector("div");   

        this.canvas = document.createElement("canvas");
        this.canvas.width = 6;
        this.canvas.height = this.scrollArea.offsetHeight - 20;
        this.canvas.setAttribute("class","scrollable");

        messagebox.parentNode.insertBefore(this.canvas,messagebox);

        const watch_scroll = (e) =>{
            this.draw();
        };
        
        this.scrollArea.addEventListener("touchmove",watch_scroll);
        this.scrollArea.addEventListener("scroll",watch_scroll);

        if(isMobile || isiPad) {
            window.addEventListener("orientationchange",this.watch.bind(this),false);
        } else {
            window.addEventListener("resize",this.watch.bind(this));
        }
        this.watch(null);
    }

    /**
     * @deprecated use Notifier.show2 instead
     * @param message
     * @param callback
     * @returns
     */
    static show(message: string | { message: string, okButtonLabel: string }, callback: (boolean) => void = null): Notifier {
        return Notifier.show2(message, callback);
    }

    static show2(message: string | { message: string, okButtonLabel: string }, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.alert2") as HTMLElement;
        if (typeof message === "string") {
            return new Notifier(message, template, callback, true);
        }
        const notifier = new Notifier(message.message, template, callback, true);
        notifier.button.textContent = message.okButtonLabel;
        return notifier;
    }

    /**
     * @deprecated use Notifier.show2 instead
     * @param message
     * @param callback
     * @returns
     */
    static unsupport(message: string, callback: (boolean) => void = null): Notifier {
        return Notifier.show2(message, callback);
    }

    /**
     * @deprecated use Notifier.show2 instead
     * @param message
     * @param callback
     * @returns
     */
    static howto(message: string, callback: (boolean) => void = null, scroll: boolean = true): Notifier {
        return Notifier.show2(message, callback);
    }

    static confirm(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.confirm.ending") as HTMLElement;
        return new Notifier(message, template, callback);
    }

    static confirm2(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.confirm.normal") as HTMLElement;
        return new Notifier(message, template, callback);
    }

    static confirmWithLabel(message: string, label: string, callback: (result: boolean) => void): Notifier {
        const view = <HTMLElement>document.getElementById("template").querySelector(".notification.confirm").cloneNode(true);
        view.querySelector(".okButton").textContent = label;
        return new Notifier(message, view, callback);
    }

    static confirmPrinting(message: string, callback: (result: ConfirmPrintingResultType) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.printing-confirm") as HTMLElement;
        const notifier = new Notifier(message, template, (result) => {
            if (!callback) { return; }
            if (result) { callback(ConfirmPrintingResultType.OK); }
            else { callback(ConfirmPrintingResultType.Cancel); }
        });
        const retryButton = notifier.alertbox.querySelector(".retryButton");
        if (retryButton) {
            retryButton.addEventListener("click", (e) => {
                const tmp = callback;
                callback = null;
                notifier.close();
                if (tmp) { tmp(ConfirmPrintingResultType.Retry); }
            });
        }
        return notifier;
    }

    static custom(message: string, buttons: string[], callback: (index: number) => void): Notifier {
        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<div class="notification custom-dialog">
    <p></p>
    <div class="row">
        ${buttons.map(label => `<button>${label}</button>`).join("")}
    </div>
</div>`;
        const template = wrapper.firstElementChild as HTMLElement;
        const notifier = new Notifier(message, template);
        Array.from(notifier.alertbox.querySelectorAll<HTMLButtonElement>("button")).forEach((button, index) => {
            button.addEventListener("click", () => {
                callback(index);
                notifier.close();
            });
        });
        return notifier;
    }

    preventScroll(e) {
        e.preventDefault();
    }

    close() {
        this.alertbox.parentNode.removeChild(this.alertbox);
        this.lightbox.removeEventListener('touchmove', this.preventScroll, false);
        this.lightbox.parentNode.removeChild(this.lightbox);
        if (this.callback) {
            this.callback(false);
            this.callback = null;
        }
    }

    watch(e) {
        if(this.scrollArea.offsetHeight < this.scrollArea.scrollHeight){
            this.scroll = true;
            //orientation changedは遅延させないとうまく高さ幅がとれない
            // 100だと早すぎて200でも微妙なので300。macだとかなりむかつくが。。
            setTimeout(()=>{
                this.resizeScroller();
            }, 300);
        } else {
            this.scroll = false;
        }
        if(this.scroll){
            this.canvas.style.display = "block";
        } else {
            this.canvas.style.display = "none";
        }
    }

    resizeScroller(){
            this.canvas.height = this.scrollArea.offsetHeight -20;
            this.draw();
    }

    draw(){
        const normal = this.scrollArea.offsetHeight / this.scrollArea.scrollHeight;
        const total_height = this.scrollArea.offsetHeight - 20;
        const bar_height = total_height * normal;
        const offset = this.scrollArea.scrollTop * normal * (this.scrollArea.scrollHeight -20) / this.scrollArea.scrollHeight;
        const ctx = this.canvas.getContext("2d");
        ctx.clearRect(0, 0, 6, this.scrollArea.scrollHeight);

        ctx.fillStyle = "#0075ff";
        ctx.fillRect(0,offset, 6, bar_height);
    }
}